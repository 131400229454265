import React, {useState, useEffect} from 'react'
import {Link } from 'react-router-dom'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'
import {baseURL} from "../api/axiosapi";
import '../style/zip.css'

function Zip(props) {
    const RPP = 50
    //const FULL_PATH = `${domainName}/docfiles/temp/`
    const axiosPrivate = useAxiosPrivate()
    const {setAuth} = useAuth()

    const [fileInfo, setFileInfo] = useState({files:0, size:0})
    const [loading, setLoading] = useState(false)
    const [page, setPage]=useState(0)
    const [zipName, setZipName]=useState(null)
    const [duration, setDuration] = useState(null)
    const [complete, setComplete] = useState(false)

    function getProgressMessage(page, total, duration){
        if(page<2){
            return "Η διαδικασία αυτή μπορεί να διαρκέσει μερικά λεπτά..."
        }
        const done = (page - 1) * RPP
        const estimatedTime= ((total - done) * duration) / done
        return `Εκτιμόμενος χρόνος που απομένει ${Math.floor(estimatedTime)} sec. Χρόνος που πέρασε  ${duration} sec` 
    }
    function calcPercent(page, total){
        const percent = Math.floor(page*RPP/total * 100)
        return (percent >100 ? 100 : percent)
    }

    function handleClick(ev){
        ev.preventDefault()
        setDuration(0)
        setComplete(false)
        setZipName(null)
        setPage(1)
    }

    useEffect(() => {
        const FLNF = "c74167ccf095ad7169370442142d5eed" // FiLes iNFo
        setLoading(true)
        axiosPrivate.post("/documents.php",{act:FLNF})
        .then(response=>{
            const {accessToken, files, size} = response.data
            setFileInfo({files,size})
            setAuth(prev=>({...prev,accessToken }))
        })
        .catch(err=>console.log(err))
        .finally(()=>setLoading(false)) 
    }, [axiosPrivate, setAuth])

    
    useEffect(() => {
        const ZIP = "60744bded8c1cc6619e7b6bafe9432a0" // ZIP
        if(page){
            axiosPrivate.post("/documents.php",{act:ZIP, total:fileInfo.files, page, zipName:zipName}) 
            .then(response => {
                const {accessToken, zipName, page, duration, complete} = response.data
                setAuth(prev=>({...prev,accessToken }))
                setZipName(zipName)
                setDuration(prev=> prev + duration)
                setComplete(complete)
                setPage(page)
                //console.log('done')
            })
            .catch(err=>console.log(err))
            .finally(()=>setLoading(false)) 
        }

    },[page,axiosPrivate,setAuth, fileInfo, zipName])

    useEffect(() => {
      if(complete){
        const newURL = `${baseURL}zipdownload.php?f=${zipName}&e=zip`;
        window.location.replace(newURL)
        setComplete(false)
      }
    }, [complete, zipName])
     

    if(loading){
        return(
            <main>
                <h4>Loading...</h4>
            </main>
        )
    }else{
        return (
            <main>
                <ul className='doc-list-bar'>
                    <li><Link to=".." className='usr-lnk'><FontAwesomeIcon icon="fa-solid fa-turn-up" flip="horizontal" /> Διαχείριση</Link></li>
                    <li>Δημιουργία αρχείου Zip</li>
                </ul>
                <div className='zip-wrapper'>
                    {
                        fileInfo.files && fileInfo.size 
                        ? <button className='zip-btn' disabled={page ? true:false} onClick={(e)=>handleClick(e)}>
                            Δημιουργία αρχείου Zip
                            {page ? <FontAwesomeIcon icon="fa-solid fa-spinner" spin  className='zip-spin'/>:''}
                        </button>
                        :<p>Δεν υπάρχουν αρχεία</p>
                    }
                    <div>
                        {page ? 
                        <div className="zip-progress-bar-container" >
                            <div className="zip-progress-bar" style={{width:`${Math.floor(page*RPP/fileInfo.files * 100)}%`}}></div>
                            <div className="zip-percent">{calcPercent(page, fileInfo.files)}%</div>
                        </div>
                        :''
                        }
                        {page ? <p className='zip-message'>{getProgressMessage(page, fileInfo.files, duration)}</p>:''}
                        {/*complete ? <a href={`${FULL_PATH}${zipName}.zip`}>{zipName}</a>:''*/}
                    </div>
                </div>
               
            </main>
            
        )

    } 
}

export default Zip