import React from 'react'
//import {domainName } from "../api/axiosapi";
import "../style/docitem.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from '../api/axiosapi'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'
import {getPageText} from '../utils/util'



function DocItem(props) {
    const {doc:d, lang, showMore, toggle} = props
    //const PATH ='/docfiles/'
    const CHKUSR = "acfef23bd008a03a85821396c4ad9743" // CHecK USeR
    const files = d?.files && d.files.split(',')
    const file= files &&  files[0]
    //const filePdf = file ?  `${domainName}${PATH}${file}`
     //const imagePath = `${process.env.PUBLIC_URL}/icons/file-pdf-sec.png`
    const content =  getPageText('docItem')
    const detailFields=[
    'format', 
    'description', 
    'series_title', 
    'work_title', 
    'subtitle', 
    'subject', 
    'composers', 
    'orch', 
    'size', 
    'revision', 
    'work_number', 
    'publisher', 
    'location', 
    'document_year', 
    'publish_number', 
    'inscription', 
    'poets']
    const rn = parseInt(d.id) % 10 + 1
    const imagePath = `${process.env.PUBLIC_URL}/icons/p${rn}.png`

    const axiosPrivate = useAxiosPrivate()
    const {setAuth, auth} = useAuth()
    
    function handleClick(fid, e){
      if(!fid || !auth || !auth?.roles  ){
        return
      }
      axiosPrivate.post("/documents.php",{fid,act:CHKUSR})
        .then(response=>{
          const {accessToken, downLoadToken,uid,fid,exp} = response.data
          console.log("dlToken:",downLoadToken, " uid:",uid," fid:",fid," exp:", exp)
          setAuth(prev=>({...prev,accessToken}))
          const newURL=`${axios.defaults.baseURL}download.php?u=${uid}&f=${fid}&e=${exp}&token=${downLoadToken}`
          console.log("url: ", newURL)
          window.location.replace(newURL)
        })
        .catch(err=>{
          const {message} = err?.response?.data
          console.log("err",message)
      })
    }
    
    const detailList =detailFields.map((fld,i)=>
        <li key={`dtl${d.id}_${i}`}>
          <div className="doci-detail-title">
            {content[lang][fld]}
          </div>
          <div className="doci-detail-text">
            {d[fld]||" - "}
          </div>
        </li>
      )


    
  return (
    <div className='doci-div'>
      <div className="doci-file">
        <button className={`doci-link ${!file && 'not-available'} ${!auth || !auth?.roles ?' no-link':''}`} 
        onClick={(e)=>{handleClick(file, e)}}
        >
          {file ? <img src={imagePath} className='sheet-music' alt="Παρτιτούρα" />:'file not available'}
          {!auth || !auth?.roles ? <FontAwesomeIcon icon="fa-solid fa-lock" className='doci-lock'/>:''}
        </button>
        <div className='doci-id' onClick={()=>toggle(d.id)}>{`ID-${d.id}`}</div>
      </div>
      <div className="doci-content">
        <div className='doci-description'>
          {d.description}
          <span className={`doci-size-${lang}`}>{d.size}</span>
        </div>
        <div className='doci-work-title'  onClick={()=>{toggle(d.id)}}>
          <span>{d.work_title}</span>
          <span className="doci-subtitle">{d.subtitle}</span>
        </div>
      
        <div className='doci-subject'>{d.subject}</div>
        <div className='doci-orch'>{d.orch}</div>
        {showMore &&
        <div >
          <ul className='doci-detail-list'>
            {detailList}
          </ul>
        </div>
        }
        <div>
          <span className="more-less" onClick={()=>{toggle(d.id)}}>
            {showMore ?`${content[lang].less}...`:`${content[lang].more}...`} 
            <FontAwesomeIcon icon={`fa-solid ${showMore?'fa-chevron-up':'fa-chevron-down'}`} style={{marginLeft:'0.5rem'}} />
          </span>
          
          {/* */}
          
          
        </div>
      </div>
    </div>
  )
}

export default DocItem