import React, {useState, useEffect, useRef} from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from '../api/axiosapi'
import useAuth from '../hooks/useAuth'
import {getPageText} from '../utils/util'
import Pagination from './Pagination'
import DocItem from './DocItem'
import '../style/search.css'

const DATA_URL="fetchdata.php"

function DocsComposer(props) {
    const {composerId} = useParams()
    const location =useLocation()
    const navigate = useNavigate()
    const {composerName}=location?.state||''
    const {lang, auth} = useAuth()
    const content =  getPageText('workComposer')
    const timer = useRef(null)

    const defaultSearch = {
        worksBycomposerId:composerId,
        lang:lang,
        rpp:50,
        page:1
      } 
    
    
    const [search, setSearch] = useState(defaultSearch)
    const [documents, setDocuments]=useState([])
    const [totalRows, setTotalRows]=useState(0)
    const [showMore, setShowMore] = useState(null)
    const [loadResult, setLoadResult]=useState(true)
    const [go, setGo]=useState(2) // 2

    const documentContent = documents && documents.map( d => 
        <DocItem doc={d} lang={lang} showMore={showMore===d.id} toggle={toggleShowMore}  key={`dk_${d.id}`}/>
      )
    
    function changePage(ev){
        const {value}=ev.target
        setSearch(prev=>({...prev,page:value}))
        setGo(2)
    }

    function toggleShowMore(id){
        setShowMore(prev=>prev===id?0:id)
    }

    useEffect(() => {
        return () => {
          if(timer.current)
              clearTimeout(timer.current)
          }
    }, [])

    useEffect(()=>{
        async function resultSearch(){
          setLoadResult(false)
          try {
            const response= await axios.post(DATA_URL, {search,type:'resultSearch', user:auth?.accessToken||null})
            const {documents, totalRows} = response.data
            setDocuments(documents)
            setTotalRows(totalRows)
            //console.log(documents)
            //console.log(response.data)
          }catch(err){
            const {error, errorMessage} =err?.response?.data
            console.log(error , errorMessage)
          }finally{
            setLoadResult(true)
            console.log("go", go)
            if(go > 1){
              timer.current = setTimeout(()=>{
                //const scroll ={top: 0, left: 0,behavior: "smooth"}
                window.scrollTo(0,0)
                
                },0)
            }
            setGo(0)
            
            
          }
    
        }
        if(go){
          resultSearch()
        }
    },[go, search, auth])
    

  return (
    <main>
        <h2>{content[lang].PageTitle} {composerName}</h2>
        <h4>{content[lang].pageSubTitle}</h4>
        {   
            !loadResult ? 
            <>
                <FontAwesomeIcon icon="fa-solid fa-spinner" spin />
                <span style={{marginLeft:'1rem'}}>{`${content[lang].loading}...`}</span>
            </>
            :''
        }
        {
            documents.length > 0 ? 
            <h4>
            ({parseInt(search.page -1)*search.rpp  + 1 } {content[lang].to} {parseInt(search.page)*search.rpp <= totalRows?parseInt(search.page)*search.rpp:totalRows}) {content[lang].from} {totalRows} {content[lang].rec}
            </h4>
            :''
        }
        {documents.length > 0 ?
            <>
              <Pagination totalRows={totalRows} currentPage={parseInt(search.page)} rpp={parseInt(search.rpp)} changePage={changePage}/>
              <button className='catalog-rtn' onClick={()=>navigate(-1)}>{content[lang].rtn}</button>
            </>
            :''
        }

        {documents.length > 0 ? documentContent:''}

        {
            documents.length > 0 ? 
            <h4>
            ({parseInt(search.page -1)*search.rpp  + 1 } {content[lang].to} {parseInt(search.page)*search.rpp <= totalRows?parseInt(search.page)*search.rpp:totalRows})  {content[lang].to} {totalRows} {content[lang].rec}
            </h4>
            :''
        }
        {documents.length > 0 ?
            <>
              <Pagination totalRows={totalRows} currentPage={parseInt(search.page)} rpp={parseInt(search.rpp)} changePage={changePage}/>
              <button className='catalog-rtn' onClick={()=>navigate(-1)}>{content[lang].rtn}</button>
            </>
            :''
        }

    </main>
    
    
  )
}

export default DocsComposer