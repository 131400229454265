import React, {useEffect, useState, useRef} from 'react'

import Select, {components} from 'react-select'
import {getPageText} from '../utils/util'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from '../api/axiosapi'
import useAuth from '../hooks/useAuth'
import Pagination from './Pagination'
import DocItem from './DocItem'
import '../style/search.css'

const DATA_URL="fetchdata.php"


function Search(props) {
  const {lang, auth} = useAuth()
  const content =  getPageText('search')
  const noOptionsText=content[lang].noOptions
  const posY=useRef(null)
  const timer = useRef(null)
  
  

  const defaultSearch = {
    format:null,
    description:null,
    series_title:null,
    work_title:null,
    subject:null,
    publisher:null,
    composer:null,
    //orchestration_id:null,
    work_number:null,
    document_year:null,
    poet:null,
    lang:lang,
    rpp:50,
    page:1
  } 


  const [search, setSearch] = useState(defaultSearch)
  const [metadata, setMetadata] = useState([])
  const [loadResult, setLoadResult]=useState(true)
  const [documents, setDocuments]=useState([])
  const [totalRows, setTotalRows]=useState(0)
  const [go, setGo]=useState(0)
  const [searchDone, setSearchDone]=useState(false)
  const [showMore, setShowMore] = useState(null)
  
  
  const publisherOptions= metadata?.options?.filter(o=>o.field==='publisher_id').map(o=>({value:o.id, label:o.des}))||[]
  const formatOptions= metadata?.options?.filter(o=>o.field==='format_id').map(o=>({value:o.id, label:o.des}))||[]
  //console.log(formatOptions)
  const desOptions= metadata?.options?.filter(o=>o.field==='description_id').map(o=>({value:o.id, label:o.des}))||[]
  const seriesTitleOptions= metadata?.options?.filter(o=>o.field==='series_title_id').map(o=>({value:o.id, label:o.des}))||[]
  const projectTitleOptions= metadata?.options?.filter(o=>o.field==='work_title_id').map(o=>({value:o.id, label:o.des}))||[]
  const subjectOptions= metadata?.options?.filter(o=>o.field==='subject_id').map(o=>({value:o.id, label:o.des}))||[]
  const projectNumberOptions= metadata?.options?.filter(o=>o.field==='work_number_id').map(o=>({value:o.id, label:o.des}))||[]
  const yearOpt= metadata?.yearOptions?.map(o=>{
    const year = o.document_year<0 ? `${Math.abs(o.document_year)} (π.Χ.)`:o.document_year.toString()
    return {value:o.document_year, label:year}
  })||[]
  const composerOptions= metadata?.composers?.map(c=>{
    const label = `${c.surname||''} ${c.first_name||''} ${c.des?`(${c.des})`:''}`.trim()
    return {value:c.id, label}
  })||[]
  const poetOptions= metadata?.poets?.map(p=>{
    const label = `${p.surname||''} ${p.first_name||''} ${p.des?`(${p.des})`:''}`.trim()
    return {value:p.id, label}
  })||[]
  //const orchestrationOptions= options.filter(o=>o.field==='orchestration_id').map(o=>({value:o.id, label:o.des}))



 /*  const documentContent = documents.map( d => 
    <div className='doc-div' key={d.id}>
      <h4>{d.work_title} [{d.id}]</h4>
      <h3>{d.subtitle}</h3>
      <p>{d.description}</p>
    </div>
  ) */
  const documentContent = documents && documents.map( d => 
    <DocItem doc={d} lang={lang} showMore={showMore===d.id} toggle={toggleShowMore}  key={`dk_${d.id}`}/>
  )

  
  useEffect(() => {
    return () => {
      if(timer.current)
          clearTimeout(timer.current)
      }
  }, [])

  useEffect(() => {
    async function getOptions(){ 
      try {
        const response= await axios.post(DATA_URL, {type:'searchOptions', lang})
        
        const {metadata} = response.data
        setMetadata(metadata)
        //console.log('options',metadata )
        
      }catch(err){
        const {error, errorMessage} =err?.response?.data
        console.log(error , errorMessage)
      }
    }
    getOptions()
    setSearch(prev=>({...prev, lang:lang}))
    //setGo(1)
    setSearchDone(false)
    setDocuments([])
  },[lang])


   useEffect(() => {
    setSearchDone(false)
    //setDocuments([])
  }, [search]) 
  
  useEffect(()=>{
    async function resultSearch(){
      setLoadResult(false)
      try {
        const response= await axios.post(DATA_URL, {search,type:'resultSearch', user:auth?.accessToken||null})
        const {documents, totalRows} = response.data
        setDocuments(documents)
        setTotalRows(totalRows)
        setSearchDone(true)
        //console.log(documents)
        //console.log(response.data)
      }catch(err){
        const {error, errorMessage} =err?.response?.data
        console.log(error , errorMessage)
      }finally{
        setLoadResult(true)
        if(go > 1){
          timer.current = setTimeout(()=>{
            const scroll ={top: parseInt(posY.current),left: 0,behavior: "smooth"}
            window.scrollTo(scroll)
            },0)
        }
        setGo(0)
      }

    }
    if(go){
      resultSearch()
    }
  },[go, search, auth])

  function toggleShowMore(id){
    setShowMore(prev=>prev===id?0:id)

  }

  function handleSelectChange(v, fld ){
   
    if(v && search[fld]===v.value)
      return
    setSearch(prev=>({...prev, [fld]:v ? parseInt(v.value):null}))
   
  }

  function searchClick(ev){
    posY.current = window.scrollY
    setSearch(prev=>({...prev, page:1}))
    setGo(2)
  }

  function changePage(ev){
    const {value}=ev.target
    setSearch(prev=>({...prev,page:value}))
    setGo(2)
}


  const DropdownIndicator = props => {
    const cl = props.selectProps.menuIsOpen ? 'book-dark':'book-light'
    //const cl ='book-light'
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {/*<FontAwesomeIcon  icon={['fas', 'book-open']} className={cl}/>*/}
          {props.selectProps.menuIsOpen ?
          <FontAwesomeIcon  icon={['fas', 'book-open']} className={cl}/> :
          props.selectProps.value ? <FontAwesomeIcon  icon={['fas', 'book-open']} className={`book-light`}/> : <FontAwesomeIcon  icon={['fas', 'book']} className={cl}/> }
        </components.DropdownIndicator>
      )
    )
  }
  /*
  const Dda = props => {
    const cl = props.selectProps.menuIsOpen ? 'book-dark':'book-light'
    return (
      components.DropdownIndicator &&  (
        <components.DropdownIndicator {...props}>
          <FontAwesomeIcon  icon={['fas', 'book-open']} className={cl}/>
        </components.DropdownIndicator>
        )
      )
  }
  */
  

  

  const customStyles = {
    control: (base, state) => ({
        ...base,
        paddingTop:'0.4rem',
        paddingBottom:'0.4rem',
        width:'100%',
        border:0,
        borderBottom:'1px solid #555555',
        boxShadow:0,
        '&:hover':{
          border:0,
          borderBottom: '1px solid #555555'
        }
    }),
      multiValueLabel: (styles) => ({
          ...styles,
          color:'#000',backgroundColor:'whitesmoke',fontSize:'1em', padding:'0.5em'
      }),
      singleValue:(styles) => ({
        ...styles,
        color:'var(--pr-color)',
        fontFamily:'Roboto',
        fontWeight:'bold',
        fontSize:'14.22pt',
        padding:'0 0.3rem'

      }),
      input:base =>({
        ...base,
       // paddingLeft: '12rem',
        fontFamily:'Arial',
        fontSize:'14.22pt',
        
      }),
      placeholder: (base,state) =>({
        ...base,
        fontSize:'14.22pt',
        fontFamily:'Roboto',
        color:'var(--txt-55-color)',
        fontWeight:'bold',
        margin:0,
        padding:'0 0.3rem',
        opacity:state.isFocused ? '0.4':'1'
      }),
      menu: base =>({
        ...base,
        width:'100%',
        
        border:0, 
        borderBottom:'1px solid #555555',
        //border:'1px solid #555555',
        outline:'none',
        boxShadow:'none',
        '&:hover':{
          border:0,
          borderBottom: '1px solid #555555'
          //border:'1px solid #555555'
        },
      }),
      menuList: base =>({
        ...base,
        "::-webkit-scrollbar": {
          width: "12px"
        },
        "scrollbarColor": "var(--pr-color) var(--sec-color)",
        "scrollbarWidth":"thin",
        "::-webkit-scrollbar-track": {
          background: "var(--sec-color)"
        },
        '::-webkit-scrollbar-thumb': {
          background: "var(--pr-color)"
        },
        '::-webkit-scrollbar-thumb:hover': {
          background: "var(--pr-color)"
        },
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        
        return {
          ...styles,
          fontWeight:400,
          fontSize:'16pt',
          fontFamily:'Roboto',
          padding:'0.3rem',
          backgroundColor: isDisabled
            ? undefined
            : isSelected
            ? 'var(--sec-color)'
            : isFocused
            ? undefined
            : undefined,
          color: isDisabled
            ? '#ccc'
            : isSelected
            ? 'white'
            :isFocused
            ? 'var(--sec-color)'
            : '#555555',
            
          cursor: isDisabled ? 'not-allowed' : 'default',
        }
      }
  }
/*
  function  filterOption (option, inputValue){
    return inputValue ?
            !option.label.substring(0,inputValue.length).localeCompare(inputValue, 'el-GR',{sensitivity:'base'}): true
  }
*/
function  filterOptionFull (option, inputValue){
  //console.log(option)
  const translate={ά:'α', έ:'ε',ή:'η',ί:'ι',ϊ:'ι',ΐ:'ι',ό:'ο',ύ:'υ',ϋ:'υ',ΰ:'υ', ώ:'ω',ς:'σ', ü :'u', é:'e', ä:'a'}
  const pattern = /[άέήίϊΐόύϋΰώςüéä]/g
  const optionStr = option.data.label.trim().toLowerCase().replace(pattern, match=>translate[match])
  const inputStr = inputValue ? inputValue.trim().toLowerCase().replace(pattern, match=>translate[match]) :''
  //console.log(option)
  return inputValue ? optionStr.includes(inputStr) : true
}

  
  return (
    <main>
      <h2>{content[lang].PageTitle}</h2>
      <h4>{content[lang].pageSubTitle}</h4>
      <section className='content-form'>
        <div>
          <Select 
            name='format' 
            id='format_id'
            components={{DropdownIndicator, IndicatorSeparator: () => null}}
            options={formatOptions} 
            isClearable={true}
            placeholder={`${content[lang].format}...`}
            isMulti={false}
            value={formatOptions.find(m=>m.value===search.format)||null}
            onChange={v=>handleSelectChange(v, 'format')}
            noOptionsMessage={()=>noOptionsText}
            className="sel-part"
            styles={customStyles}
            menuPlacement='auto'
            filterOption = {filterOptionFull}
           
            
          />

          <Select 
            name='description' 
            id='description_id'
            components={{DropdownIndicator, IndicatorSeparator: () => null}}
            options={desOptions} 
            isClearable={true}
            placeholder={`${content[lang].description}...`}
            isMulti={false}
            value={desOptions.find(m=>m.value===search.description)||null}
            onChange={v=>handleSelectChange(v, 'description')}
            noOptionsMessage={()=>noOptionsText}
            className="sel-part"
            styles={customStyles}
            menuPlacement='auto'
            filterOption = {filterOptionFull}
          />
      

        <Select 
            name='series_title' 
            id='series_title_id'
            components={{DropdownIndicator, IndicatorSeparator: () => null}}
            options={seriesTitleOptions} 
            isClearable={true}
            placeholder={`${content[lang].seriesTitle}...`}
            isMulti={false}
            value={seriesTitleOptions.find(m=>m.value===search.series_title)||null}
            onChange={v=>handleSelectChange(v, 'series_title')}
            noOptionsMessage={()=>noOptionsText}
            className="sel-part"
            styles={customStyles}
            menuPlacement='auto'
            filterOption = {filterOptionFull}
          />

          <Select 
            name='work_title' 
            id='work_title_id'
            components={{DropdownIndicator, IndicatorSeparator: () => null}}
            options={projectTitleOptions} 
            isClearable={true}
            placeholder={`${content[lang].workTitle}...`}
            isMulti={false}
            value={projectTitleOptions.find(m=>m.value===search.work_title)||null}
            onChange={v=>handleSelectChange(v, 'work_title')}
            noOptionsMessage={()=>noOptionsText}
            className="sel-part"
            styles={customStyles}
            menuPlacement='auto'
            filterOption = {filterOptionFull}
          />
          <Select 
            name='subject' 
            id='subject_id'
            components={{DropdownIndicator, IndicatorSeparator: () => null}}
            options={subjectOptions} 
            isClearable={true}
            placeholder={`${content[lang].subject}...`}
            isMulti={false}
            value={subjectOptions.find(m=>m.value===search.subject)||null}
            onChange={v=>handleSelectChange(v, 'subject')}
            noOptionsMessage={()=>noOptionsText}
            className="sel-part"
            styles={customStyles}
            menuPlacement='auto'
            filterOption = {filterOptionFull}
          />
        </div>
        <div>
          <Select 
              name='composer' 
              id='composer_id'
              components={{DropdownIndicator, IndicatorSeparator: () => null}}
              options={composerOptions} 
              isClearable={true}
              placeholder={`${content[lang].composer}...`}
              isMulti={false}
              value={composerOptions.find(m=>m.value===search.composer)||null}
              onChange={v=>handleSelectChange(v, 'composer')}
              noOptionsMessage={()=>noOptionsText}
              className="sel-part"
              styles={customStyles}
              menuPlacement='auto'
              filterOption = {filterOptionFull}
            />
          {/*
          <Select 
            name='orchestration_id' 
            id='orchestration_id'
            components={{DropdownIndicator, IndicatorSeparator: () => null}}
            options={orchestrationOptions} 
            isClearable={true}
            placeholder={'Ενορχήστρωση...'}
            isMulti={false}
            value={orchestrationOptions.find(m=>m.value===search.orchestration_id)||null}
            onChange={v=>handleSelectChange(v, 'orchestration_id')}
            noOptionsMessage={()=>noOptionsText}
            className="sel-part"
            styles={customStyles}
            menuPlacement='auto'
            filterOption = {filterOptionFull}
          />
          */}
          <Select 
            name='work_number' 
            id='work_number_id'
            components={{DropdownIndicator, IndicatorSeparator: () => null}}
            options={projectNumberOptions} 
            isClearable={true}
            placeholder={`${content[lang].workNumber}...`}
            isMulti={false}
            value={projectNumberOptions.find(m=>m.value===search.work_number)||null}
            onChange={v=>handleSelectChange(v, 'work_number')}
            noOptionsMessage={()=>noOptionsText}
            className="sel-part"
            styles={customStyles}
            menuPlacement='auto'
            filterOption = {filterOptionFull}
          />

          <Select 
            name='publisher' 
            id='publisher_id'
            components={{DropdownIndicator, IndicatorSeparator: () => null}}
            options={publisherOptions} 
            isClearable={true}
            placeholder={`${content[lang].publisher}...`}
            isMulti={false}
            value={publisherOptions.find(m=>m.value===search.publisher)||null}
            onChange={v=>handleSelectChange(v, 'publisher')}
            noOptionsMessage={()=>noOptionsText}
            className="sel-part"
            styles={customStyles}
            menuPlacement='auto'
            filterOption = {filterOptionFull}
          />

          <Select 
            name='document_year' 
            id='document_year'
            components={{DropdownIndicator, IndicatorSeparator: () => null}}
            options={yearOpt} 
            isClearable={true}
            placeholder={`${content[lang].workYear}...`}
            isMulti={false}
            value={yearOpt.find(m=>m.value===search.document_year)||null}
            onChange={v=>handleSelectChange(v, 'document_year')}
            noOptionsMessage={()=>noOptionsText}
            className="sel-part"
            styles={customStyles}
            menuPlacement='auto'
            filterOption = {filterOptionFull}
          />

          <Select 
            name='poet' 
            id='poet_id'
            components={{DropdownIndicator, IndicatorSeparator: () => null}}
            options={poetOptions} 
            isClearable={true}
            placeholder={`${content[lang].poet}...`}
            isMulti={false}
            value={poetOptions.find(m=>m.value===search.poet)||null}
            onChange={v=>handleSelectChange(v, 'poet')}
            noOptionsMessage={()=>noOptionsText}
            className="sel-part"
            styles={customStyles}
            menuPlacement='auto'
            filterOption = {filterOptionFull}
          />
        </div>
        <div className='btn-cont'>
            
            
            <button type='button' disabled={!loadResult} onClick={(e)=>searchClick(e)}>
            {!loadResult?
              <>
                <FontAwesomeIcon icon="fa-solid fa-spinner" spin />
                <span>{`${content[lang].loading}...`}</span>
              </>:`${content[lang].buttonText}`}
            </button>   
        </div>
          
      
      </section>
      
      {documents.length > 0 || searchDone ? <h2>{content[lang].resultTitle}</h2>:''}
      {
        documents.length > 0 ? 
        <h4>
        ({parseInt(search.page -1)*search.rpp  + 1 } {content[lang].to} {parseInt(search.page)*search.rpp <= totalRows?parseInt(search.page)*search.rpp:totalRows}) {content[lang].from} {totalRows} {content[lang].rec}
        </h4>
        :searchDone ? <h4>{content[lang].noResultText}</h4> :''
      }
      {documents.length > 0 ?
      <Pagination totalRows={totalRows} currentPage={parseInt(search.page)} rpp={parseInt(search.rpp)} changePage={changePage}/>
        :''
      }

      {documents.length > 0 ? documentContent:''}

      {
        documents.length > 0 ? 
        <h4>
        ({parseInt(search.page -1)*search.rpp  + 1 } {content[lang].to} {parseInt(search.page)*search.rpp <= totalRows?parseInt(search.page)*search.rpp:totalRows})  {content[lang].to} {totalRows} {content[lang].rec}
        </h4>
        :''
      }
      {documents.length > 0 ?
      <Pagination totalRows={totalRows} currentPage={parseInt(search.page)} rpp={parseInt(search.rpp)} changePage={changePage}/>
        :''
      }
     {/*
      <footer>
        <div className='social'>
          <FontAwesomeIcon icon="fa-brands fa-instagram" />
          <FontAwesomeIcon icon="fa-brands fa-facebook" />
          <FontAwesomeIcon icon="fa-solid fa-envelope" />
        </div>
        <div className='design'>
          NO IDEA graphic design
        </div>
      </footer>
      */}
    
    </main>
  )
 
}

export default Search