import React, {useState, useReducer, useEffect} from 'react'
import axios,{ axiosUpload, domainName } from "../api/axiosapi";
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../style/uploadreduse.css'

const CHUNK_SIZE = 256*1024
const PATH='/docfiles/temp/'




const reducer = (state, action)=>{
    //console.log('init',state.uploadedFiles)
    switch (action.type){
        case 'load-files':
            const {ev}=action  //const {ev, permit}=action
            ev.preventDefault()
            if(state.progress){
                return state
            }
            const eventTypeFiles = ev.dataTransfer ? ev.dataTransfer.files : ev.target.files
            //console.log(ev)
            //const fileArr = Array.from(ev.dataTransfer.files).filter(file=>file.size > 0)
            const fileArr = Array.from(eventTypeFiles).filter(file=>file.size > 0) // check permission ?
            //console.log(fileArr)
            //console.log('restrict', permit)
            const arr = fileArr.map(file => {
                const fileName = `${uuidv4()}.${file.name.split('.').pop()}`
                return {file, tmpName:fileName, id:0}
            }) 
            return{...state, files:[...state.files, ...arr ]}

        case 'change-files-length':
            if(state.files.length > 0) {
                if(state.currentFileIndex === null){
                    return {...state, currentFileIndex: state.lastUploadedFileIndex === null ? 0:state.lastUploadedFileIndex + 1, progress:true}
                }
             }  
             return state
        case 'change-lastUploadedFileIndex': 
            if (state.lastUploadedFileIndex === null) {
                return state
            }
            const isLastFile = state.lastUploadedFileIndex === state.files.length - 1;
            const nextFileIndex = isLastFile ? null : state.currentFileIndex + 1;
           
            return{...state, currentFileIndex:nextFileIndex, progress:!isLastFile }

        case 'reset-chunk-index':
            if(state.currentFileIndex !== null){
                return{...state, currentChunkIndex:0}
            }  
            return state

        case 'skip-empty-file':
            return {...state, lastUploadedFileIndex:action.payload, currentChunkIndex:null}

        case 'update-currentFileIndex':
            if (action.payload) {
                const fileItem ={
                  id:0, 
                  path:PATH, 
                  org_path:null,
                  file_name: state.files[state.currentFileIndex].tmpName, 
                  org_file_name: state.files[state.currentFileIndex].file.name || null, 
                  file_type: state.files[state.currentFileIndex].file.type||null, 
                  file_size: state.files[state.currentFileIndex].file.size, 
                }
                const upf =[...state.uploadedFiles, fileItem ]
                //const newDelTbl = state.deletedFiles.filter(d=>d!==fileItem.file_name)
                //console.log(state.files[state.currentFileIndex].tmpName)
                //action.ufn(upf)
                
                return {...state, lastUploadedFileIndex:state.currentFileIndex, currentChunkIndex:null, uploadedFiles:upf}   
              }else{
                return {...state, currentChunkIndex:state.currentChunkIndex + 1}
              }   
        case 'delete-uploaded-file':
          const newTbl = state.uploadedFiles.filter( f=> f.file_name !== action.payload)
          const newDelTbl = [...state.deletedFiles, action.payload]
          return {...state, uploadedFiles:newTbl, deletedFiles:newDelTbl }
        case 'uplf':
          return {files:[],
            currentFileIndex:null,
            currentChunkIndex:null,
            lastUploadedFileIndex:null,
            progress:false,
            uploadedFiles:action.payload,
            deletedFiles:[]}
        default:
            return state

    }
}

function UploadReduse(props) {

  const {uploadFileList, appendFiles, updateButton} = props
  const initialState = {
    files:[],
    currentFileIndex:null,
    currentChunkIndex:null,
    lastUploadedFileIndex:null,
    progress:false,
    uploadedFiles:[],
    deletedFiles:[],
}
 
  const FILE_PATH=`${domainName}${PATH}`
  const[state, dispatch] = useReducer(reducer, initialState)
  const [dropzoneActive, setDropzoneActive] = useState(false)
  const DLTF = "1086cb3f1e2ae7ce09b0d92884f516e8" // DeLeTe File
  //const [files, setFiles] = useState([])
  //const [currentFileIndex, setCurrentFileIndex] = useState(null)
  //const [lastUploadedFileIndex, setLastUploadedFileIndex ] = useState(null)
  //const [currentChunkIndex, setCurrentChunkIndex] = useState(null);
  
  function handleDrag(e){
    e.preventDefault()
    setDropzoneActive(e.type === 'dragover')
  }

  function deleteUploadedFile(filename, id){
    if(parseInt(id)!==0){
      dispatch({type:'delete-uploaded-file', payload:filename})
    }else{
      axios.post("upload/filemanager.php", {filename,act:DLTF})
      .then(res=>{
        const {deletedFile} = res.data
        dispatch({type:'delete-uploaded-file', payload:deletedFile})
      })
      .catch(err=>{
        console.log(err)
      })
    }
  }
 
  function  saveFiles(ev){
   // const upl = uploadFileList.filter(u=> !state.deletedFiles.includes(u.file_name) )
    //const tbl = state.uploadedFiles.concat(upl)
       
       props.appendFiles(state.uploadedFiles)
      
  }
  useEffect(() => {
    dispatch({type:'change-files-length'})
  }, [state.files.length])
  
  useEffect(() => {
    dispatch({type:'change-lastUploadedFileIndex'})
  }, [state.lastUploadedFileIndex])
  
  useEffect(() => {
    dispatch({type:'reset-chunk-index'})
  }, [state.currentFileIndex])

  useEffect(() => {

    dispatch({type:'uplf', payload:uploadFileList})
  }, [uploadFileList])

  useEffect(()=>{
    if(!updateButton){
      appendFiles(state.uploadedFiles)
    }
  },[updateButton,appendFiles, state.uploadedFiles])
  useEffect(()=>{
    if(!updateButton){
      appendFiles(state.progress)
    }
  },[updateButton,appendFiles, state.progress])

  useEffect(() => {
    if (state.currentChunkIndex !== null) {
        function readAndUploadCurrentChunk(){
        
            const reader = new FileReader()
            const file = state.files[state.currentFileIndex]
            if(!file){
             return
            }
            if(file.file.size===0){
                dispatch({type:'skip-empty-file', payload:state.currentFileIndex})
                return
            }
            const from = state.currentChunkIndex * CHUNK_SIZE
            const to = from + CHUNK_SIZE
            const blob = file.file.slice(from,to)
            reader.onload = e => uploadChunk(e)
            reader.readAsDataURL(blob)
        }
        function uploadChunk(readerEvent){
            const file = state.files[state.currentFileIndex]
            const data = readerEvent.target.result
            //console.log(data)
            const formData = new  FormData()
            formData.append('fileContent',data)
            formData.append('fileName',file.tmpName)
            axiosUpload.post("upload/", formData)
            .then(res =>{
              //const file = files[currentFileIndex]
              const filesize = state.files[state.currentFileIndex].file.size
              const chunks = Math.ceil(filesize / CHUNK_SIZE) - 1
              const isLastChunk = state.currentChunkIndex === chunks
        
              dispatch({type:'update-currentFileIndex', payload:isLastChunk})
              
            })
            
        }
        readAndUploadCurrentChunk()
    }
    //// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentChunkIndex, state.files, state.currentFileIndex]);


   

  //console.log(state.files, state)
  const uploadResult = state.files.length >0 ? state.files.map((file,i)=>{
        const chunks = Math.ceil(file.file.size / CHUNK_SIZE);
        const currentSize = state.currentChunkIndex *  CHUNK_SIZE //+ CHUNK_SIZE

        const progressPC=Math.round(state.currentChunkIndex / chunks * 100);
        //console.log(progressPC)
        if(i===state.currentFileIndex){
            return(
            <li key={`f_${i}`} className='progress-active'>
              <div className="file-name">
                {file.file.name}
              </div>
              <div className='file-size'>
                ({currentSize > file.file.size? file.file.size:currentSize} / {file.file.size}) 
              </div>
              <div className="progress-bar">
                <div className="bar" style={{width:`${progressPC}%`}}></div>
                <div className="size-pc">{progressPC}%</div>
              </div>
            </li>)
        }else if(i<=state.lastUploadedFileIndex){
            if (state.deletedFiles.includes(file.tmpName)){
              return ''
            }
            return(<li key={`f_${i}`}>
                <div className="file-name">
                  <a href={`${FILE_PATH}${file.tmpName}`}  className="file-link" target="_blank" rel="noreferrer" >{file.file.name}</a> 
                </div>  
                <div className='file-size'>
                  {file.file.size}
                </div>
                <FontAwesomeIcon icon={['fas', 'trash-alt']} className='del-file' onClick={(e)=>deleteUploadedFile(file.tmpName,file.id)} />
            </li>)
        }else{
            return(<li key={`f_${i}`}>
                    <div className="file-name">
                      {file.file.name}
                    </div>
                    <div className='file-size'>
                      0 / {file.file.size}
                    </div>
                  </li>)
        }
        
    }):''

    const alreadyUploaded = props.uploadFileList.length > 0 ? 
      props.uploadFileList.map((f,i) =>{
        if (state.deletedFiles.includes(f.file_name)){
          return ''
        }
        const base = domainName
        return (<li key={`f_${i}`}>
          <div className="file-name">
            <a href={`${base}${f.path}${f.file_name}`}  className="file-link" target="_blank" rel="noreferrer" >{f?.org_file_name||f.file_name}</a> 
          </div>  
          <div className='file-size'>
            {f.file_size}
          </div>
          <FontAwesomeIcon icon={['fas', 'trash-alt']} className='del-file' onClick={(e)=>deleteUploadedFile(f.file_name, f.id)} />
        </li>)

    }):''

  return (
    <div className='file-uploader'>
      <h3>{props.title}</h3>
      <ul className="file-list">
        {alreadyUploaded}
        {uploadResult}
      </ul>
      <label htmlFor='selectFile' className={`drop-zone ${dropzoneActive?'active-dz':''} ${state.progress?'progress':''}` }
        onDragOver={e=>handleDrag(e)}
        onDragLeave={e=>handleDrag(e)}
        onDrop={e=>dispatch({type:'load-files', ev:e, permit:['png','jpeg']})}
       >
        {props.droptext}
        <input type='file' style={{display:'none'}} id="selectFile" disabled={state.progress} multiple={true} onChange={e=>dispatch({type:'load-files', ev:e, permit:['png','jpeg']})} />
      </label>
      {/*<button name='btnSave' onClick={(e)=>{saveDacument(e)}}>
        {inProgress ?<FontAwesomeIcon icon="fa-solid fa-spinner" spin />:''}
        Ενημέρωση
      </button>*/}
      {/*console.log('upload',state.uploadedFiles)*/}
      {!state.progress && props.updateButton?
      <button name='btnSaveFileList' onClick={(e)=>saveFiles()}>Ενημέρωση</button>
      :''}
    </div>
  )
}

UploadReduse.defaultProps={
  title:"Upload file",
  droptext:"Drop your files here...",
  uploadFileList:[],
  updateButton:true
}
export default UploadReduse