import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom'
import Layout from './Layout'
//import Catalog from './components/Catalog' //-----------------
import CatalogComposer from './components/CatalogComposer';
import DocsComposer from './components/DocsComposer';
import Contact from './components/Contact'
import Guide from './components/Guide'
import Login from './components/Login'
import Profile from './components/Profile'
import Search from './components/Search'
import './App.css';
import Archive from './components/Archive'
import Dashboard from './components/Dashboard';
import DashboardLayout from './components/DashboardLayout';
import useAuth from './hooks/useAuth';
import PersistLogin from './components/PersistLogin';
//import Gauth from './components/Gauth';
import RequireAuth from './components/RequireAuth';
import Admin from './components/Admin';
import SearchUser from './components/SearchUser';
import User from './components/User';
import AdminArchive from './components/AdminArchive';
import SearchDocument from './components/SearchDocument';
import Document from './components/Document';
import Options from './components/Options';
import Zip from './components/Zip';
//import axios from './api/axiosapi'

function App() {

  const {auth} = useAuth()

  /*
  useEffect(() => {
    if(!auth?.user){
      const REFRESH_URL = "refresh.php"
      async function refresh(){
        try{
        const response = await axios.get(REFRESH_URL, {
            withCredentials:true
        })
        setAuth(prev => {
            console.log('prev',prev)
            console.log(response.data.accessToken)
            const accessToken=response?.data?.accessToken || false
            const roles = response?.data?.roles 
            const userID = response?.data?.userID || false
            return {user:userID, roles, accessToken}
        })
      // return response.data.accessToken
        }catch(err){
          setAuth({})
        }

      }
      refresh()
    }
  }, [auth.user, setAuth])
  */
  
  return (
   
      <Routes>
        <Route element={<PersistLogin />}>
          <Route  path="/" element={<Layout />} >
            <Route index element={<Archive />} />
            {/*  <Route path="catalog" element={<Catalog />} /> */}
            
            <Route path="catalog">
              <Route index element={<CatalogComposer />} />
              <Route path=":composerId" element={<DocsComposer />} />
            </Route>

            <Route path="guide" element={<Guide />} />
            <Route path="contact" element={<Contact />} />
            <Route path="login" element={auth?.user ? <Navigate to="/"/> : <Login />} />
            <Route path="profile" element={auth?.user ? <Profile/>:<Navigate to="/" />} />
            <Route path="search/*" element={<Search />} />
            
            
            {/*<Route path="*" element={<Navigate to="/"/>} />*/}
            <Route path="*" element={<Archive />} />
          </Route>
          <Route element={<RequireAuth allowedUsers={100}/>} >
            <Route path="admin" element={<Admin />}>
              <Route index element={<Dashboard />} />
              <Route path="users" element={<DashboardLayout />} > 
                <Route index element={<SearchUser />} />
                <Route path=":uid" element={<User />} />
              </Route>
              <Route path="documents" element={<DashboardLayout />} >
                <Route index element={<SearchDocument />} />
                <Route path=":docId" element={<Document />} />
              </Route>
             
              <Route path="homepage" element={<AdminArchive />} />
              <Route path="guidepage" element={<div>guide...</div>} />
              <Route path="commpage" element={<div>communication...</div>} />
              <Route path="options" element={<Options />} />
              <Route path="zip" element={<Zip />} />
            </Route>
          </Route>
        </Route>
      </Routes>
  
  )
}

export default App;
