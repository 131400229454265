import React, {useState, useEffect, useRef} from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import {getPageText} from '../utils/util'
import useAuth from '../hooks/useAuth'
import axios from '../api/axiosapi'
import Pagination from './Pagination'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../style/search.css'

const DATA_URL="fetchdata.php"

function CatalogComposer(props) {
    const {lang} = useAuth()
    const content =  getPageText('catalogComposer')
    const location = useLocation()
    const timer = useRef(null)

    const defaultSearch = {
        letter:null,
        lang:lang,
        rpp:50,
        page:1
    } 
    const letters={
        gb:['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
        gr:['Α','Β','Γ','Δ','Ε','Ζ','Η','Θ','Ι','Κ','Λ','Μ','Ν','Ξ','Ο','Π','Ρ','Σ','Τ','Υ','Φ','Χ','Ψ','Ω']
    }
    const searchKey = `${location.pathname}-composer-search`
    const alphabetKey = `${location.pathname}-composer-alphabet`
   
    const [search, setSearch] = useState(sessionStorage.getItem(searchKey)?JSON.parse(sessionStorage[searchKey]):defaultSearch)
    const [loadResult, setLoadResult]=useState(true)
    const [go, setGo]=useState(0)
    const [searchDone, setSearchDone]=useState(false)
    const [currentAlphabet, setCurrentAlphabet]=useState(sessionStorage.getItem(alphabetKey)||lang)
    const [composers, setComposers]=useState([])
    const [totalRows, setTotalRows]=useState(0)


    const alphabet = letters[currentAlphabet].map((a,i)=>
      <button 
        className={`letter ${search.letter===a ? 'selected':''}`} key={i}
        onClick={(e)=>searchByLetter(a,e)}
      >
        {a}
      </button> 
    )

    const composerContent = composers && composers.map( (c,i) => 
        <li key={`cmpk_${i}`} className='composer'>
            <div className='composer-name'>
                { c.docs ?
                <NavLink className="composer-link" to={c.id.toString()} state={{composerName:c.composer_name}} onClick={()=>storeLocal()}>
                    {c.composer_name}
                </NavLink>
                :
                    c.composer_name
                }
            </div>
            <div className='composer-works'>{c.docs}</div>
        
        </li>
    )

    function storeLocal(){
        //console.log()
        sessionStorage.setItem(location.pathname, window.scrollY)
        sessionStorage.setItem(searchKey, JSON.stringify(search))
    }

    function searchByLetter(letter,ev){
        setSearch(prev=>({...prev, page:1,letter}))
        setGo(2)
    }
    
    function changeAlphabet(ab){
        sessionStorage.setItem(alphabetKey,ab)
        setCurrentAlphabet(ab)
    }

    function changePage(ev){
        const {value}=ev.target
        setSearch(prev=>({...prev,page:value}))
        setGo(2)
    }
 
     useEffect(() => {
        if(lang!==search.lang){
            setSearch(prev=>({...prev,letter:null, lang:lang}))
            //setGo(1)
            setSearchDone(false)
            setComposers([])
        }
    },[lang, search]) 
 
    useEffect(()=>{
        function handleScroll(){
            const position = sessionStorage.getItem(location.pathname)
            if(position){
                timer.current = setTimeout(()=>{
                window.scrollTo(0,parseInt(position))
                sessionStorage.removeItem(location.pathname)
                },0)
                console.log('pos:' , parseInt(position))
            }
        }
        async function resultSearch(){
          setLoadResult(false)
          try {
            const response= await axios.post(DATA_URL, {search,type:'resultComposer'})
            const {composers, totalRows} = response.data
            setComposers(composers)
            setTotalRows(totalRows)
            setSearchDone(true)
            //console.log(documents)
            //console.log(response.data)
            if(go === 1){
                handleScroll()
              }else if(go===2){
                window.scrollTo(0,0)
                //console.log('zero')
            }
          }catch(err){
            const {error, errorMessage} =err?.response?.data
            console.log(error , errorMessage)
          }finally{
            setLoadResult(true)
            //const scroll ={top: 0,left: 0,behavior: "smooth"}
            //window.scrollTo(scroll)
            //setGo(0)
          } 
    
        }
        if(go){
            if(search.letter){
                resultSearch()
            }
        }
    },[go, search, location.pathname])

    useEffect(() => {
        setGo(1)
        return () => {
          if(timer.current)
              clearTimeout(timer.current)
          }
    }, [])
  

  return (
    <main>
        <h2>{content[lang].PageTitle}</h2>
        <h4>{content[lang].pageSubTitle}</h4>
        <section className='content-catalog'>
            <div>
                <button className={`alphabet-selector ${currentAlphabet==='gr' ? 'selected':''}`}
                    onClick={()=>changeAlphabet('gr')}
                >
                    ΕΛ
                </button>
                <button className={`alphabet-selector ${currentAlphabet==='gb' ? 'selected':''}`}
                    onClick={()=>changeAlphabet('gb')}
                >
                    EN
                </button>  
                </div>
                <div>
                {alphabet}
                </div>
                <div className='load-sign'>
                {!loadResult?
                    <>
                    <FontAwesomeIcon icon="fa-solid fa-spinner" spin />
                    <span style={{marginLeft:'1rem'}}>{`${content[lang].loading}...`}</span>
                    </>:''
                }
            </div>
        </section>
        {composers.length > 0 || searchDone ? <h2>{content[lang].resultTitle}</h2>:''}
        {
            composers.length > 0 ? 
            <h4>
            ({parseInt(search.page -1)*search.rpp  + 1 } {content[lang].to} {parseInt(search.page)*search.rpp <= totalRows?parseInt(search.page)*search.rpp:totalRows}) {content[lang].from} {totalRows} {content[lang].rec}
            </h4>
            :searchDone ? <h4>{content[lang].noResultText}</h4> :''
        }
        {composers.length > 0 ?
        <Pagination totalRows={totalRows} currentPage={parseInt(search.page)} rpp={parseInt(search.rpp)} changePage={changePage}/>
            :''
        }

        {composers.length > 0 ? <ul className='composer-content'>{composerContent}</ul>:''}

        {
            composers.length > 0 ? 
            <h4>
            ({parseInt(search.page -1)*search.rpp  + 1 } {content[lang].to} {parseInt(search.page)*search.rpp <= totalRows?parseInt(search.page)*search.rpp:totalRows})  {content[lang].to} {totalRows} {content[lang].rec}
            </h4>
            :''
        }
        {composers.length > 0 ?
        <Pagination totalRows={totalRows} currentPage={parseInt(search.page)} rpp={parseInt(search.rpp)} changePage={changePage}/>
            :''
        }
    </main>
  )
}

export default CatalogComposer