import axios  from 'axios'
//const DOMAIN = "http://localhost/mandolinata" // localhost
//const DOMAIN = "https://athenianmandolinataarchive.com"

const DOMAIN = `https://${window.location.hostname}`

const BASE_URL = `${DOMAIN}/api/`          


//const BASE_URL = "https://kkourakos.eu/mandolinata/api/" //------------------------ Άκυρο
//const BASE_URL = "https://athenianmandolinataarchive.com/api/"  // on-line

export default  axios.create({
    baseURL:BASE_URL,
    headers:{
        'Content-Type':'application/json',
    },
})

export const axiosPrivate = axios.create({
    baseURL:BASE_URL,
    headers:{
        'Content-Type':'application/json'
    },
    withCredentials:true
})

export const axiosUpload = axios.create({
    baseURL:BASE_URL,
    headers:{
        'content-type': 'multipart/form-data'
    },
})

export const domainName = `${DOMAIN}`
export const baseURL = `${BASE_URL}`





