import React, {useState} from 'react'
import {Outlet} from "react-router-dom"
import logo1 from '../media/img/logo-71.png'
import useAuth from '../hooks/useAuth'
import jwtDecode from 'jwt-decode';
import '../style/admin.css'

function Admin() {
    const {auth} = useAuth();
    const token = auth?.accessToken || null
    const {uinfo} = jwtDecode(token)
    const [admin] = useState(uinfo||null)
   

  return (
    <div className={`container`}>
        <header>
          <div className='logo'>
            <img src={logo1} alt="logo"/>
            <div className='logo-des'>
              <h4>ΑΡΧΕΙΟ</h4>
              <h3>ΑΘΗΝΑΪΚΗΣ</h3>
              <h3>ΜΑΝΔΟΛΙΝΑΤΑΣ</h3>
            </div>    
          </div>
          <div className='admin-info'>
            <span className='admin-des'>Διαχειριστής</span> 
            <span className='admin-name'>{admin}</span>
          </div>
        </header>
        <Outlet />
        <footer>
          
        </footer>
    </div>
  )
}

export default Admin