import React from 'react'
import { useLocation, Navigate, Outlet  } from 'react-router-dom'
import useAuth from '../hooks/useAuth'

function RequireAuth({allowedUsers}) {
  const {auth} = useAuth()
  const location = useLocation()
  //console.log('ReqAuth', auth)
  return (
        parseInt(auth?.roles) === parseInt(allowedUsers)
            ? <Outlet />
            : auth?.user 
                ? <Navigate to="/" state ={{from:location}} replace /> 
                : <Navigate to="/login" state ={{from:location}} replace /> 
        )
}

export default RequireAuth