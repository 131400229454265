import React, {useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Dashboard() {
    const navigate = useNavigate()
    const content = [
        {icon:'fa-users', des:'Χρήστες' ,linkTo:'users'},
        {icon:'fa-house', des:'Αρχική - Αρχείο', linkTo:'homepage'},
        { icon:'fa-book', des:'Οδηγίες', linkTo:'guidepage'},
        {icon:'fa-at',des:'Επικοινωνία',linkTo:'commpage'},
        {icon:'fa-music',des:'Μουσικά κομμάτια',linkTo:'documents'},
        {icon:'fa-gear',des:'Διαχείριση επιλογών',linkTo:'options'},
        {icon:'fa-file-zipper', des:'Δημιουργία Αρχείου zip', linkTo:'zip'}
    ];
    const dashboardContent = content.map((item, i)=>(
        <div className='dashboard-item' key={`di_${i}`}>
            <Link to={item.linkTo} className='link' >
                <FontAwesomeIcon icon={`fa-solid ${item.icon}`} className="dashboard-icon" />
                <div className='link-des'>{item.des}</div>
            </Link>
        </div>
    ))

    useEffect(() => {
        sessionStorage.clear()
    }, [])
    
  return (
   <>
    <h1>Διαχείριση Εφαρμογής</h1>
    <div className='admin-dashboard'>
       {dashboardContent}
    </div>
    <div style={{fontSize:'1.6rem', marginTop:'2rem'}}>
        <button className='link-like-btn' style={{fontSize:'1.2rem',marginLeft:'0'}}  onClick={()=>{navigate("../profile", { replace: true })}}>Επιστροφή</button>
    </div>
   </>
  )
}

export default Dashboard