import React, {useEffect, useState, useRef} from 'react'
import {Link, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import axios from '../api/axiosapi';
import  useAxiosPrivate from '../hooks/useAxiosPrivate'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {getPageText} from '../utils/util'
import '../style/profile.css'



function Profile() {
    const PFL="fa287a1785a15d27d0aab781e67585b6"; // ProFiLe
    const PFLUPD = "8dd92e78c236b64f0619f2e66eb65fa2"; // ProFiLe UpDaTe
    const PUDP = "816037ad52c644c8235f2b62a773d499"; // Profile UpDate Password
    const navigate = useNavigate()
    const axiosPrivate  = useAxiosPrivate()
    const {auth, setAuth, lang} = useAuth()
    const timerID=useRef(null)

    const [profile, setProfile] = useState(null)
    const [showPassCurrent, setShowPassCurrent]=useState(false)
    const [showPassNew, setShowPassNew]=useState(false)
    const [changePass, setChangePass]=useState(false)
    const [currentPass, setCurrentPass] = useState(null)
    const [newPass, setNewPass] = useState(null)
    const [info, setInfo] = useState({cl:'',msg:'', clp:'', msgp:''})

    const content = getPageText("profile")

    function handleChange(ev){
        const {name, value} = ev.target
        setProfile(prev=>({...prev, [name]:value || null}))
    }

    function multilineError(err){
        return err.split('\n').map((item, i )=>(<div key={`er_${i}`}>{item}</div>))
    }

    function clearInfo(mode=''){
        if(mode==='pass'){
            setInfo(prev=>({...prev, clp:'', msgp:''}))
        }else{
            setInfo(prev=>({...prev, cl:'', msg:''}))
        }
    }

    function remainDays(admin, expire, lang="gr"){
        if(!admin && expire){
            const color= expire >=0 ?'var(--suc-color)':'var(--err-color)' 
            if(lang==="gb"){
                return(
                    <span style={{fontSize:'1rem', fontWeight:'normal', marginLeft:'0.5rem', color:color}}>
                        {expire >= 0 ? ` (${expire} days remain)`:`(Subscription has expired) ` }
                    </span>
                )
            }
            return(
                <span style={{fontSize:'1rem', fontWeight:'normal', marginLeft:'0.5rem', color:color}}>
                    {expire >= 0 ? ` (Απομένουν ${expire} ημέρες)`:`(Η συνδρομή έχει λήξει) ` }
                </span>
            ) 
        }
        return ''
    }
    
    async function logout(){
            try{
                const response = await axios.get("logout.php", {
                    withCredentials:true
                });
                sessionStorage.clear()
                setAuth(null);
                console.log(response)
                navigate('/', {replace:true}); // from
            }catch(err){
                console.log("logout error:", err)
            }

        }

    async function updateForm(ev){
        ev.preventDefault()
        try{
            const response = await axiosPrivate.post("users.php",{user:profile,act:PFLUPD, lang:lang})
            const {message, affected, accessToken} = response.data
            const cl = affected ? 'suc-msg':'inf-msg'
            setInfo(prev=>({...prev,cl:cl, msg:message}))
            setAuth(prev=>({...prev,accessToken }))

        }catch(err){
            const {error, message} = err.response.data
            const msg=`[${error}] ${message}`
            setInfo(prev=>({...prev,cl:'err-msg', msg:msg}))
        }finally{
            timerID.current = setTimeout(()=>{
                setInfo(prev=>({...prev,cl:'', msg:''}))
            },3000)
        }
    }

    async function updatePass(ev){
        ev.preventDefault()
        try{
            const id=profile?.id||null
            const user_name=profile?.user_name||null
            const response = await axiosPrivate.post("users.php",{id,user_name,currentPass, newPass,act:PUDP, lang:lang})
            const {message, affected, accessToken} = response.data
            const cl = affected ? 'suc-msg':'inf-msg'
            setInfo(prev=>({...prev,clp:cl, msgp:message}))
            setAuth(prev=>({...prev,accessToken }))

        }catch(err){
            const {error, message} = err.response.data
            const msg=`[${error}] ${message}`
            setInfo(prev=>({...prev,clp:'err-msg', msgp:msg}))
        }finally{
            timerID.current = setTimeout(()=>{
                setInfo(prev=>({...prev,clp:'', msgp:''}))
            },3000)
        }
    }
    useEffect(() => {
        const timer = timerID.current
        window.scrollTo(0,0)
        return () => {
            clearTimeout(timer)
        }
    }, [])

    useEffect(()=>{
        const scroll ={top: 0,left: 0,behavior: "smooth"}
        window.scrollTo(scroll)
        const getProfile = async ()=>{
            try{
                const response = await axiosPrivate.post("users.php",{userID:auth?.user, act:PFL})
                const {contact} = response.data
                setProfile(contact)
            }catch(err){
                console.log(err)
            } 
        }
        if(auth?.user)
            getProfile();
        //// eslint-disable-next-line
    },[axiosPrivate, auth])

    /* useEffect(() => {
      setInfo(prev=>({...prev,cl:'', msg:''}))
    }, [profile])
 */
    useEffect(() => {
        setInfo(prev=>({...prev,clp:'', msgp:''}))
      }, [currentPass, newPass])
    

  return (
    <div>
        <div className="hdfl">
            <h1>{profile ? `${content[lang].PageTitle}`: ''}</h1>
            <span className='logout-lnk'>
                <label  onClick={()=>logout()}>{content[lang].logout}</label>
                <FontAwesomeIcon icon={['fas', 'right-from-bracket']} className='logout-icon'  onClick={()=>logout()}/>
            </span> 
        </div>
        <h3>{profile?.user_name}</h3>
        {
            profile?.is_admin 
            ? <div className='admin-box'>
                <Link to="/admin" className='admin-lnk'style={{padding:'1.5rem 0'}}>
                    <FontAwesomeIcon icon={['fas' ,'gear']} style={{fontSize:'1.5rem', marginRight:'0.5rem'}}/>
                    Διαχείριση Εφαρμογής
                    
                </Link>
            </div>
            : ''
        }
        <div className="flw">
            <div>
                <h4>
                    {
                        profile?.expire_date && !profile?.is_admin ?
                        `${content[lang].expdatetext}: ${profile?.expire_date.split('-').reverse().join('-')}`
                        : profile?.is_admin ? '':`${content[lang].notactiveyet}`
                    }
                    {remainDays(profile?.is_admin, profile?.expire, lang)}
                </h4>
                <label htmlFor='firstname'>{content[lang].firstname}</label>
                <input type="text" name="first_name" id="firstname" value={profile?.first_name || ''} onChange={(e)=>handleChange(e)}/>
                <label htmlFor='surname'>{content[lang].surname}</label>
                <input type="text" id="surname" name="surname" value={profile?.surname || ''} onChange={(e)=>handleChange(e)}/>
                <label htmlFor='tel'>{content[lang].tel}</label>
                <input type="text" id="tel" name="tel" value={profile?.tel || ''} onChange={(e)=>handleChange(e)}/>
                <label htmlFor='contactemail'>{content[lang].contactemail}</label>
                <input type="text" id="contactemail" name="contact_email" value={profile?.contact_email || ''} onChange={(e)=>handleChange(e)}/>
                <div className={`info-box-profile ${info?.cl||''}`} >
                    {info?.msg? <FontAwesomeIcon className='close-info' icon={['fas', 'circle-xmark']}  onClick={()=>clearInfo()} />:''}
                    {info?.msg? multilineError(info.msg):''}
                </div>
                <button type='button' name="btnUpd" onClick={(e)=>updateForm(e)}>{content[lang].updacc}</button>
            </div>
            <div>
               
                <h4 className="ch-pass" onClick={()=>{setChangePass(prev=>!prev)}}>
                    
                    {content[lang].changepass}
                    <FontAwesomeIcon icon={['fas', changePass ? 'chevron-up':'chevron-down']} style={{float:'right'}} />
                </h4>
                { changePass ?
                <div className='pass-box'>
                    <label htmlFor='curpass'>
                        {content[lang].curpass}   
                        <FontAwesomeIcon className='sp-icon' icon={['fas', showPassCurrent ? 'eye-slash':'eye']}  onClick={()=>{setShowPassCurrent(prev=>!prev)}} />
                    </label>
                    <input type={showPassCurrent ?"text":"password"} id='curpass' name="currentPass" value={currentPass||''} onChange={(e)=>setCurrentPass(e.target.value||null)}/>
                    
                    <label htmlFor='newpass'>
                        {content[lang].newpass}
                        <FontAwesomeIcon className='sp-icon' icon={['fas', showPassNew ? 'eye-slash':'eye']}  onClick={()=>{setShowPassNew(prev=>!prev)}} />
                    </label>
                    <input type={showPassNew ?"text":"password"} id='newpass' name="newPass" value={newPass||''} onChange={(e)=>setNewPass(e.target.value||null)}/>
                   
                    <div className={`info-box-profile ${info?.clp ? info.clp:''}`} >
                        {info?.msgp? <FontAwesomeIcon className='close-info' icon={['fas', 'circle-xmark']}  onClick={()=>clearInfo('pass')} />:''}
                        {info?.msgp? multilineError(info.msgp):''}
                    </div>
                    <button type='button' name="btnUpdPass" onClick={(e)=>updatePass(e)}>{content[lang].changepass}</button>
                </div>:''
                }
            
                
            </div>
        </div>
        
    </div>
  )
}

export default Profile