import React, {useState, useRef, useEffect} from 'react'
import {Link, useLocation } from 'react-router-dom'
import {Confirm} from 'react-st-modal'
import Select from 'react-select'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { country2emoji2 } from '../utils/util'
import Pagination from './Pagination'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'
import '../style/document.css'

function SearchDocument(props) {
    
    const axiosPrivate = useAxiosPrivate()
    const {setAuth} = useAuth()
    const noOptionsText="Δεν υπάρχουν επιλογές"
    const DLTDOC = "7e4d77cb40d1ec31da6ff26300e673a4" // DeLeTe DOCument
    const searchDocument = {
        id:null,
        description:[],
        series_title:[],
        work_title:[],
        subject:[],
        publisher:[],
        composer:[],
        poet:[],
        lang:'gr',
        rpp:50,
        page:1
    }
    const location = useLocation()
    const searchKey = `${location.pathname}-search`
    const detailKey = `${location.pathname}-detail`
    const [search, setSearch]=useState(sessionStorage.getItem(searchKey)?JSON.parse(sessionStorage[searchKey]):searchDocument)
    const [document, setDocument] = useState([])
    const [options, setOptions] = useState([])
    const [composer, setComposer]=useState([])
    const [poet, setPoet]=useState([])
    const [totalRows, setTotalRows] = useState(0)
    const [go, setGo] = useState(0)
    const [loading, setLoading] = useState(false)
    const [showDetails, setShowDetails] = useState(sessionStorage.getItem(detailKey)?parseInt(sessionStorage[detailKey]):0);
    const timer = useRef(null)

    const descriptionOptions = options.filter(o=>(o.field ==='description_id')).map(v=>({value:v.id, label:v.des}))
    const seriesTitleOptions = options.filter(o=>(o.field ==='series_title_id')).map(v=>({value:v.id, label:v.des}))
    const workTitleOptions = options.filter(o=>(o.field ==='work_title_id')).map(v=>({value:v.id, label:v.des}))
    const subjectOptions = options.filter(o=>(o.field ==='subject_id')).map(v=>({value:v.id, label:v.des}))
    const publisherOptions = options.filter(o=>(o.field ==='publisher_id')).map(v=>({value:v.id, label:v.des}))
    const composerOptions = composer.map(c=>{
        const fullName = `${c?.surname||''} ${c?.first_name||''}`
        const des = c?.des ? `(${c.des})`:''
        const label = fullName.concat(' ', des)
        return({
            value:c.id,
            label:label.trim()
        })
    })
    const poetOptions = poet.map(c=>{
        const fullName = `${c?.surname||''} ${c?.first_name||''}`
        const des = c?.des ? `(${c.des})`:''
        const label = fullName.concat(' ', des)
        return({
            value:c.id,
            label:label.trim()
        })
    })
   
    function changeLang(ev){
        //console.log(ev.target.name, ":", ev.target.value)
        const {name, value} = ev.target
        setSearch(prev=>({...prev, [name]:value}))
        //setSearch({...searchDocument, [name]:value})
        setGo(3)
    }

    function toggleDetails(id,ev){
        //console.log(id)
        const pr =sessionStorage.getItem(detailKey) || 0
        sessionStorage.setItem(detailKey,parseInt(pr)===parseInt(id)?0:id)
        //console.log(pr)
        setShowDetails(prev=> prev===id?0:id)
        
    }

    function  filterOptionFull (option, inputValue){
        //console.log(option)
        const translate={ά:'α', έ:'ε',ή:'η',ί:'ι',ϊ:'ι',ΐ:'ι',ό:'ο',ύ:'υ',ϋ:'υ',ΰ:'υ', ώ:'ω',ς:'σ', ü :'u', é:'e', ä:'a'}
        const pattern = /[άέήίϊΐόύϋΰώςüéä]/g
        const optionStr = option.data.label.trim().toLowerCase().replace(pattern, match=>translate[match])
        const inputStr = inputValue ? inputValue.trim().toLowerCase().replace(pattern, match=>translate[match]) :''
        //console.log(option)
        return inputValue ? optionStr.includes(inputStr) : true
      }
      const customStyles = {
        control: (base, state) => ({
            ...base,
            width:'100%',
            border:'1px solid var(--sec-color)',
            boxShadow:0,
            '&:hover':{
                borderColor:'var(--sec-color)',
              }
            
        }),
        menu: base =>({
            ...base,
            width:'100%', 
            border:'1px solid var(--sec-color)',
            //border:'1px solid #555555',
            outline:'none',
            boxShadow:'none',
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isDisabled
                ? undefined
                : isSelected
                ? 'rgb(179, 126, 172,0.25)'
                : isFocused
                ? 'rgb(179, 126, 172,0.25)'
                : undefined,
              
                
              cursor: isDisabled ? 'not-allowed' : 'default',
            }
          }
      }

      function handleSearch(ev){
        //setGo(1);
        setShowDetails(0)
        sessionStorage.removeItem(detailKey)
        setSearch(prev=>({...prev, page:1}))
        setGo(2)
    }

    function clearSearch(ev){
        //setSearch(initSearch())
        setSearch(prev=>({...searchDocument,lang:prev.lang}))
        sessionStorage.removeItem(searchKey)
    }

    function storeLocal(){
        //console.log()
        sessionStorage.setItem(location.pathname, window.scrollY)
        sessionStorage.setItem(searchKey, JSON.stringify(search))
    }

    function deleteDocument(id){
        axiosPrivate.post("/documents.php",{id,act:DLTDOC})
        .then(response=>{
            const {accessToken} = response.data
            setAuth(prev=>({...prev,accessToken }))
            setGo(1)
            //setInfo({msg:message,cl:'suc-msg'})
            
        })
        .catch(err=>{
           
            const {message}=err?.response?.data
            console.log(message)
            //setInfo({msg:message,cl:'err-msg'})
        })
        .finally(()=>{
            //setInProgress(false)
            timer.current = setTimeout(()=>{
                //setInfo(defaultInfo)
            },3000)
        })
    }
    

    function changePage(ev){
        const {value}=ev.target
        setSearch(prev=>({...prev,page:value}))
        setGo(2)
    }

    function handleMultiSelectChange(e, field){
        console.log(e, field)
        setSearch(prev=>({...prev,[field]:Array.isArray(e)?e.map(x=>x.value):[]}))
    }
    

    function handleTextChange(ev){
        
        const {name, value} = ev.target
        //const valID = parseInt(value)
        setSearch(prev=>({...prev, [name]:value|| parseInt(value)===0?value:null}))
       
    }

    useEffect(() => {
        
        function handleScroll(){
            const position = sessionStorage.getItem(location.pathname)
            if(position){
                timer.current = setTimeout(()=>{
                window.scrollTo(0,parseInt(position))
                sessionStorage.removeItem(location.pathname)
                },0)
                //console.log('pos:' , parseInt(position))
            }
        }
        if(go){
            setLoading(true)
            if(go === 2 || go ===3){
               window.scrollTo(0,0)
               //console.log('zero')
            }
            const optionFlag = go === 1 || go === 3
            sessionStorage.setItem(searchKey, JSON.stringify(search))
            axiosPrivate.post("/documents.php",{search,optionFlag})
            .then(response=>{
                const {accessToken, document, totalRows, options, composer, poet} = response.data
                setDocument(document)
                if(options){
                    setOptions(options)
                }
                if(composer){
                    setComposer(composer)
                }
                if(poet){
                    setPoet(poet)
                }
                setAuth(prev=>({...prev,accessToken }))
                setTotalRows(totalRows)
                if(go === 1){  //  if(go === 1)
                    handleScroll()
                }
                setGo(0) 
            })
            .catch(err=>console.log(err)
            )
            .finally(()=>setLoading(false)) 
        }
    },[go, search, setAuth, axiosPrivate, searchKey, location.pathname])

    
    
    useEffect(() => {
      setGo(1)
      return () => {
        if(timer.current)
            clearTimeout(timer.current)
        }
    }, [])

    const documentContent = document.map((d,i)=>{
        return(
            <ul key={`d${i}`} className='doc-item'>
                <li className='doc-ID'>
                    <Link className='usr-lnk' to={d.id.toString()} state={{lang:search.lang}} onClick={()=>storeLocal()}>
                        ID-{d.id}
                    </Link>
                </li>
                <li className='doc-work-title' data-label="Τίτλος:">{d.work_title||'-'}</li>
                <li className='doc-work-number'>{d?.work_number?`[${d.work_number}]`:''}</li>
                <li className="doc-series-title" data-label="Σειρά:">{d.series_title||'-'}</li>
                <li className="doc-subtitle" data-label="Υπότιτλος:">{d.subtitle||''}</li>
                <li className="doc-description" data-label="Περ:">{d.description||'-'}</li>
                <li className="doc-subject" data-label="Θέμα:">{d.subject||'-'}</li>
                
                <li className="doc-orchestration" data-label="Ενορχήστρωση:">{d.orch||'-'}</li>
                <li className="doc-poet" data-label="Στίχοι:">{d.poets||'-'}</li>
                <li className="doc-composer" data-label="Συνθέτης:">{d.composers||'-'}</li>
                <li className="doc-publisher" data-label="Εκδ:">{d.publisher||'-'}</li>
                <li className='doc-detail'>
                   
                    {showDetails===d.id ?
                    <div className='detail-content'>
                        <ul className='detail'>
                            <li data-label="Μορφότυπο">{d.format || '-'}</li>
                            <li data-label="Έκταση Τεκμηρίου">{d.size?`${d.size} σελ.`:''}</li>
                            <li data-label="Διασκευή">{d.revision||'-'}</li>
                            <li data-label="Τόπος">{d.location||'-'}</li>
                            <li data-label="Χρονολογία">{d.document_year||'-'}</li>
                            <li data-label="Αρ. Έκδοσης">{d.publish_number}</li>
                            <li data-label="Αφιέρωση">{d.inscription||'-'}</li>
                        </ul>
                    </div>
                    :''
                    }
                     <span className='edit'>
                        <Link className='usr-lnk' to={d.id.toString()} state={{lang:search.lang}} onClick={()=>storeLocal()}>
                            <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                        </Link>
                    </span>
                    <span className='left' onClick={(e)=>toggleDetails(d.id,e)}>
                        <FontAwesomeIcon icon={`fa-solid ${showDetails===d.id?'fa-chevron-up':'fa-chevron-down'}`} />
                        <span>
                            {showDetails===d.id?' Λιγότερα...':' Περισσότερα...'}
                        </span>
                    </span>
                    <span className='right'>
                        <FontAwesomeIcon icon="fa-solid fa-trash-alt" onClick={async () => {
                            const result = await Confirm("Να διαγραφεί η εγγραφή ?", 
                            'Διαγραφή Χρήστη', 'Ναι', 'Όχι')
                            if (result) {
                                deleteDocument(d.id)
                            } 
                      }}/>
                    </span>
                </li>
                    {/*
                    <span style={{color:'var(--sec-color'}}>{d.work_title}{d.work_number?` [${d.work_number}]`:''}</span>
                    <span style={{color:'var(--txt-55-color'}}> {d.series_title||''}</span>
                
                <div>{d.subtitle}</div>
                <div>{d.description} - {d.subject}</div>
                <div style={{fontSize:'0.8rem',color:'var(--pr-color)'}}>{d.orch}</div>
                */}
            </ul>
        )
    })
  
    return (
    <main>
        <ul className='doc-list-bar'>         
            <li><Link to=".." className='usr-lnk'><FontAwesomeIcon icon="fa-solid fa-turn-up" flip="horizontal" /> Διαχείριση</Link></li>
            <li>Μουσικά Κομμάτια</li>
            
            <li>
                <select className='lang' style={{padding:'0',margin:'0'}} name='lang' value={search.lang} onChange={(e)=>changeLang(e)}>
                    <option value="gr">{country2emoji2('gr')}</option>
                    <option value="gb">{country2emoji2('gb')}</option>
                </select>
            </li>
            <li><FontAwesomeIcon icon="fa-solid fa-eraser" className='eraser' onClick={()=>{clearSearch()}}/></li> {/**  */}
            <li className="r-pos">
                <Link className='new-lnk' to="new" onClick={()=>storeLocal()}>{/*  */}
                    <FontAwesomeIcon  icon='fa-solid fa-music'/>
                    <FontAwesomeIcon  icon='fa-solid fa-plus' style={{fontSize:'0.8rem'}}/>
                </Link>
            </li>
        </ul>
        <div className='site-path'>
            
            <ul className='search-container'>
                <li>
                    <label>ID</label>
                    <input type='text' name="id" value={search.id||''} onChange={(e)=>{handleTextChange(e)}}/>
                </li>
                <li>
                    <label>Περιγραφή</label>
                    <Select 
                        name='description' 
                        id='description_id'
                        options={descriptionOptions} 
                        isClearable={true}
                        isMulti={true}
                        value={descriptionOptions.filter(d=>search.description.includes(d.value))}
                        onChange={e=>handleMultiSelectChange(e, 'description')}
                        noOptionsMessage={()=>noOptionsText}
                        menuPlacement='auto'
                        filterOption = {filterOptionFull}    
                        styles={customStyles}
                        placeholder={''}
                    />
                </li>
                <li>
                    <label>Τίτλος Σειράς</label>
                    <Select 
                        name='series_title' 
                        id='series_title_id'
                        options={seriesTitleOptions} 
                        isClearable={true}
                        isMulti={true}
                        value={seriesTitleOptions.filter(d=>search.series_title.includes(d.value))}
                        onChange={e=>handleMultiSelectChange(e, 'series_title')}
                        noOptionsMessage={()=>noOptionsText}
                        menuPlacement='auto'
                        filterOption = {filterOptionFull}    
                        styles={customStyles}
                        placeholder={''}
                    />
                </li>
                <li>
                    <label>Τίτλος Έργου</label>
                    <Select 
                        name='work_title' 
                        id='work_title_id'
                        options={workTitleOptions} 
                        isClearable={true}
                        isMulti={true}
                        value={workTitleOptions.filter(d=>search.work_title.includes(d.value))}
                        onChange={e=>handleMultiSelectChange(e, 'work_title')}
                        noOptionsMessage={()=>noOptionsText}
                        menuPlacement='auto'
                        filterOption = {filterOptionFull}    
                        styles={customStyles}
                        placeholder={''}
                    />
                </li>
                <li>
                    <label>Θέμα</label>
                    <Select 
                        name='subject' 
                        id='subject_id'
                        options={subjectOptions} 
                        isClearable={true}
                        isMulti={true}
                        value={subjectOptions.filter(d=>search.subject.includes(d.value))}
                        onChange={e=>handleMultiSelectChange(e, 'subject')}
                        noOptionsMessage={()=>noOptionsText}
                        menuPlacement='auto'
                        filterOption = {filterOptionFull}    
                        styles={customStyles}
                        placeholder={''}
                    />
                </li>
                <li>
                    <label>Συνθέτης</label>
                    <Select 
                        name='composer' 
                        id='composer_id'
                        options={composerOptions} 
                        isClearable={true}
                        isMulti={true}
                        value={composerOptions.filter(d=>search.composer.includes(d.value))}
                        onChange={e=>handleMultiSelectChange(e, 'composer')}
                        noOptionsMessage={()=>noOptionsText}
                        menuPlacement='auto'
                        filterOption = {filterOptionFull}    
                        styles={customStyles}
                        placeholder={''}
                    />
                </li>
                <li>
                    <label>Εκδότης</label>
                    <Select 
                        name='publisher' 
                        id='publisher_id'
                        options={publisherOptions} 
                        isClearable={true}
                        isMulti={true}
                        value={publisherOptions.filter(d=>search.publisher.includes(d.value))}
                        onChange={e=>handleMultiSelectChange(e, 'publisher')}
                        noOptionsMessage={()=>noOptionsText}
                        menuPlacement='auto'
                        filterOption = {filterOptionFull}    
                        styles={customStyles}
                        placeholder={''}
                    />
                </li>
                <li>
                    <label>Ποιητής / Στιχουργος</label>
                    <Select 
                        name='poet' 
                        id='poet_id'
                        options={poetOptions} 
                        isClearable={true}
                        isMulti={true}
                        value={poetOptions.filter(d=>search.poet.includes(d.value))}
                        onChange={e=>handleMultiSelectChange(e, 'poet')}
                        noOptionsMessage={()=>noOptionsText}
                        menuPlacement='auto'
                        filterOption = {filterOptionFull}    
                        styles={customStyles}
                        placeholder={''}
                    />
                </li>
              
                <li className='srch-btn'>
                    <button onClick={(e)=>handleSearch(e)}>
                        {loading ?
                            <FontAwesomeIcon icon="fa-solid fa-spinner" spin />
                        :
                            <FontAwesomeIcon icon='fa-solid fa-magnifying-glass'/>
                        }
                    </button>
                </li>
            </ul>
            
        </div>
        {!loading && document.length >0  ?
        <Pagination totalRows={totalRows} currentPage={parseInt(search.page)} rpp={parseInt(search.rpp)} changePage={changePage}/>
        :''
        }
        <div>
            {document.length > 0 ?documentContent :!loading ? 'Δεν υπάρχουν εγγραφές':'Loading...'}
        </div>
        {
        document.length > 0 ?
        <div>
        ({parseInt(search.page -1)*search.rpp  + 1 } έως {parseInt(search.page)*search.rpp <= totalRows?parseInt(search.page)*search.rpp:totalRows}) από {totalRows} εγγραφές
        </div>
        :''
        }
        <Pagination totalRows={totalRows} currentPage={parseInt(search.page)} rpp={parseInt(search.rpp)} changePage={changePage}/>
    </main>
  )
}

export default SearchDocument