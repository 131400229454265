import React, {useRef, useState, useEffect} from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import '../utils/fontawesome'
import {getPageText} from '../utils/util'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from '../api/axiosapi'
import '../style/login.css'

//const DATA_URL="fetchdata.php"
const LOGIN_URL="login.php"


//const ERRMSG = "Error Message: "

function Login() {
  const CLG = "ba8368adef72d0456488eed707299ea6"; // Check LoGin;
  const EVF = "88c883734a6a039024c87a875b453b05"; // E-mail VeriFication
  const CRA = "8b1ed2f724098860248425afb0820771"; // CReate Account
  const REG = "ef034b6e8f93b40ff27588dfca9b9c0e"; // REGister
  const ERS = "530b32791421046fa29ee9c6b051bc35"; // E-mail ReSend
  const CPQ  = "b7beef870e6d5fe073c07861737d1786"; // Change Password
  const EVFCP = "0535b4626b38f4c2221faaab892cd07c"; // Email Verification For Changing Password
  const CP = "6cae2cb40ff047673d33ddc746f5c725"; // Change Password
  const {setAuth, lang} = useAuth()

  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || "/"

  const userRef = useRef()
  const errRef = useRef()
  const pwdRef = useRef()
  const fNameRef = useRef()

  const content = getPageText("login")
  
  const [user,setUser] = useState(sessionStorage.getItem('usr')||'')
  const [firstName,setFirstName] = useState(null)
  const [surname,setSurname] = useState(null)
  const [passwd, setPasswd] = useState(null)
  const [tel, setTel]=useState(null)
  const [activationKey, setActivationKey] = useState(sessionStorage.getItem('actK')||null)
  const [verificationCode, setVerificationCode]=useState('')
  //const [passwdvf, setPasswdvf] = useState('')
  const [err, setErr]=useState(0)
  const [errMsg, setErrMsg] = useState('')
  const [loginStatus, setLoginStatus]=useState(sessionStorage.getItem('signupproc')||null)
  const [showPass, setShowPass]=useState(false)
  const [inProccess, setInProccess]=useState(false)
  const [resendMailRequest, setResendMailRequest]=useState(false)

  useEffect(()=>{
    if(!loginStatus){
      userRef.current.focus()
    }else if (loginStatus==="REG"){
      fNameRef.current.focus()
    }
    
  },[loginStatus])

  useEffect(()=>{
    setErrMsg('')
    setErr(0)
  }, [user,passwd, verificationCode])
  
  useEffect(()=>{
    window.scrollTo(0,0)
  }, [])
  async function handleLogin(e){
    e.preventDefault()
    try{
      const response = await axios.post(LOGIN_URL,
        {username:user, pass:passwd, act:CLG, lang:lang},
        {withCredentials:true}
      )
      //console.log(response?.data)
      //console.log(response)
      const accessToken=response?.data?.accessToken || false
      const roles = response?.data?.roles 
      const userID = response?.data?.userID || false
      setAuth({user:userID, roles, accessToken})
      setUser('')
      setPasswd('')
      navigate(from, {replace:true})
    }catch (err){
      if(!err?.response){
       console.log( "NO SERVER RESPONSE")
       setErrMsg("NO SERVER RESPONSE")
      }else if(err.response?.status === 409){
          console.log(err.response)
      }else{
        console.log(err?.response?.data?.message)
        setErrMsg(`(${err.response?.status}) ${err?.response?.data?.message}`)
      }
      errRef.current.focus() // for screen readers
    }
  }

  async function handleSignUp(e){
    e.preventDefault();
    setShowPass(false);
    setInProccess(true)
    
    try{
      const response = await axios.post(LOGIN_URL, 
        {first_name:firstName, surname,username:user, pass:passwd, tel:tel, activation_key:activationKey, act:loginStatus, lang:lang},
        {withCredentials:true}
      )
      //console.log(response?.data)
      const {message} = response?.data?.message ||''
      const accessToken=response?.data?.accessToken || false
      const roles = response?.data?.roles 
      const userID = response?.data?.userID || false
      setErrMsg(message)
      setAuth({user:userID, roles, accessToken})
      sessionStorage.clear()
      navigate('/', {replace:true})
    }catch(err){
      const {activation_key, status,error,message} = err?.response?.data
        setErr(error)
        setErrMsg(`(${error}) ${message}`)
        sessionStorage.setItem('signupproc',status)
        sessionStorage.setItem('actK', activation_key)
        setActivationKey(err?.response?.data?.activation_key)
        setLoginStatus(err?.response?.data?.status)
        
    }finally{
      setInProccess(false)
    }

  }

  async function handleChangePass(e){
    e.preventDefault();
    setShowPass(false);
    setInProccess(true);
    try{
      const response = await axios.post(LOGIN_URL, 
        { username:user,pass:passwd,activation_key:activationKey, act:loginStatus, lang:lang},
        
      )
      
      const {message} = response?.data?.message ||''
      
      setErrMsg(message)
      clearAll()
    }catch(err){
      const {activation_key, status,error,message} = err?.response?.data
        setErr(error)
        setErrMsg(`(${error}) ${message}`)
        sessionStorage.setItem('signupproc',status)
        sessionStorage.setItem('actK', activation_key)
        setActivationKey(err?.response?.data?.activation_key)
        setLoginStatus(err?.response?.data?.status)
    }finally{
      setInProccess(false)
    }

  }

  async function handleMailVerification(e){
    e.preventDefault()
    setInProccess(true)
    try{
      const response = await axios.post(LOGIN_URL, 
        {username:user, activation_key:activationKey, verification_code:verificationCode, act:loginStatus, lang:lang}
        
      )
      const {username, status,activation_key} = response?.data
      sessionStorage.setItem('signupproc',status)
      sessionStorage.setItem('actK', activation_key)
      sessionStorage.setItem('usr',username)
      setActivationKey(activation_key)
      setUser(username)
      setLoginStatus(status)
    }catch(err){
      const{error} = err?.response?.data
      setErr(error)
      setErrMsg(`(${err.response?.data?.error}) ${err?.response?.data?.message}`)
    }finally{
      setInProccess(false)
    }
  }

  async function reSendMail(e, logStat){
    e.preventDefault();
    setVerificationCode('')
    setResendMailRequest(true)
    setInProccess(true)
    const act = logStat===EVF?ERS:CPQ
    try{
      const response = await axios.post(LOGIN_URL, 
        {username:user,activation_key:activationKey, act:act, lang:lang}  
      )
      const {error,message,username, activation_key, status} = response?.data
      sessionStorage.setItem('signupproc',status)
      sessionStorage.setItem('actK', activation_key)
      sessionStorage.setItem('usr',username)
      setActivationKey(activation_key)
      setUser(username)
      setLoginStatus(status)
      setErr(error)
      setErrMsg(message||'')

    }catch(err){
      const{error} = err?.response?.data
      setErr(error)
      setErrMsg(`(${err.response?.data?.error}) ${err?.response?.data?.message}`)
    }finally{
      setInProccess(false)
      setResendMailRequest(false)
    }

  }

  async function handleCreateAccount(e){
    //const pattern = /^([a-z0-9+_\-?&]+)(\.[a-z0-9+_\-?&]+)*@([a-z0-9-]+\.)+[a-z]{2,6}$/gi
    e.preventDefault();
    setInProccess(true)
    try{
      const response = await axios.post(LOGIN_URL, 
        {username:user, act:loginStatus, lang:lang}
      )
      const {error, message, activation_key, status, username}=response?.data
      sessionStorage.setItem('signupproc',status)
      sessionStorage.setItem('actK',activation_key)
      sessionStorage.setItem('usr',username)
      setErr(error)
      setErrMsg(message||'')
      setActivationKey(activation_key)
      setUser(username)
      setLoginStatus(status)

    }catch(err){
      const {error} = err?.response?.data
      setErr(error)
      setErrMsg(`(${err.response?.data?.error}) ${err?.response?.data?.message}`)
    }finally{
      setInProccess(false)
    }
  }

 


  function signUp(ev){
    sessionStorage.setItem('signupproc', CRA)
    setUser('')
    setPasswd('')
    setErr('')
    setErrMsg('')
    setLoginStatus(CRA)
  }

  function changePass(ev){
    sessionStorage.setItem('signupproc', CPQ)
    setUser('')
    setPasswd('')
    setErr('')
    setErrMsg('')
    setLoginStatus(CPQ)
  }


  function clearAll(){
    sessionStorage.clear()
    setLoginStatus(null)
    setUser(null)
    setPasswd(null)
    setFirstName(null)
    setSurname(null)
    setTel(null)
    setActivationKey(null)
    setVerificationCode(null)
    setErrMsg(null)
    setErr(0)
    setInProccess(false)
    setResendMailRequest(false)
  }


  function cancelSignUp(ev){
    ev.preventDefault();
    clearAll()
  }

  function toggleShowPass(){
    setShowPass(prev=>!prev)
  }

  function multilineError(err){
    return err.split('\n').map((item, i )=>(<div key={`d_${i}`}>{item}</div>))
  }

  if(!loginStatus){
    return (
      <section>
        <h2>{content[lang].PageTitle}</h2>
        <h4>{content[lang].pageSubTitle}  <label  className='signup'   onClick={(e)=>signUp(e)}>{content[lang].registerText}</label></h4>
        <form className='login-frm' onSubmit={(e)=>handleLogin(e)}>
          <div ref={errRef} className={errMsg ? "err-msg":"no-err"}>{errMsg}</div>
          <label htmlFor='username'>e-mail</label>
          <input type="text" ref={userRef} id="username" value={user||''} onChange={(e)=>setUser(e.target.value)}/>

          <label htmlFor='pwd'>Password</label>
          <input type="password" id="pwd" value={passwd||''} onChange={(e)=>setPasswd(e.target.value)}/>
          
          <button name="btnSignIn" type="submit" className='login-btn'  id="btnGO">{content[lang].loginText}</button>
          <label  className='signup' style={{maxWidth:'170px'}}  onClick={(e)=>changePass(e)}>{content[lang].resetPass}</label>
          <h4 className='signup-prompt'>{content[lang].signUpPromptQ} <label  className='signup'   onClick={(e)=>signUp(e)}>{content[lang].signUpPromptA}</label></h4>
         
        </form>
        
      </section>
    )
  }else if(loginStatus === CRA ||loginStatus === CPQ){
    return(
      <section>
        <h2>{loginStatus === CRA ? content[lang].registerTitle : content[lang].resetPass}</h2>
        <form className='login-frm' onSubmit={(e)=>handleCreateAccount(e)}>
          <div ref={errRef} className={err?"err-msg":"no-err"}>{errMsg}</div>
          
          <label htmlFor='username'>e-mail</label>
          <input type="text" ref={userRef} id="username" value={user||''} onChange={(e)=>setUser(e.target.value)}/>
          <button name="btnCheckEmail" type="submit" className='login-btn'  id="btnCheckEmail">
            {content[lang].submit} {inProccess?<FontAwesomeIcon icon="fa-solid fa-spinner" spin />:'' }
          </button>
          <p>
            <button className='link-like' onClick={(e)=>cancelSignUp(e)}>{content[lang].return}</button>
          </p>
        </form>
      </section>
    )
  }else if(loginStatus===REG){
    return(
      <section>
        <h2>{lang==="gb"?`Registration`:`Eγγραφή`}</h2>
        
        <form className='login-frm' onSubmit={(e)=>handleSignUp(e)}>
          <div ref={errRef} className={errMsg ? "err-msg":"no-err"}>{multilineError(errMsg)}</div>
          <h3>{user}</h3>
          {/*<h3>{activationKey}</h3>*/}
          <label htmlFor='firstName' className='mandatory'>{lang==="gb"?`First name`:`Όνομα`}</label>
          <input type="text" ref={fNameRef}  id="firstName" value={firstName||''} onChange={(e)=>setFirstName(e.target.value)}/>

          <label htmlFor='surname' className='mandatory'>{lang==="gb"?`Surname`:`Επώνυμο`}</label>
          <input type="text"  id="surname" value={surname||''} onChange={(e)=>setSurname(e.target.value)}/>
          {/*
          <label htmlFor='username'>e-mail</label>
          <input type="text" ref={userRef} id="username" value={user} onChange={(e)=>setUser(e.target.value)}/>
          */}
          <label htmlFor='pwd' className='mandatory'>Password <FontAwesomeIcon icon={['fas', showPass ? 'eye-slash':'eye']} className="icons pwd-icon" onClick={()=>toggleShowPass()} /></label>
          <input type={showPass ? 'text' : 'password'} ref={pwdRef} id="pwd" value={passwd||''} onChange={(e)=>setPasswd(e.target.value)}/>
          
          <label htmlFor='tel'>{lang==="gb"?`Phone number`:`Τηλέφωνο`}</label>
          <input type="text"  id="tel" value={tel||''} onChange={(e)=>setTel(e.target.value)}/>
      {/*
          <label htmlFor='pwdvf'>Confirm Password</label>
          <input type="password" id="pwdvf" value={passwdvf} onChange={(e)=>setPasswdvf(e.target.value)}/>
    */}
         {/* <div className='login-btn-area'>
            <button name="btnSignUp" disabled={inProccess} type="submit" className='login-btn'  id="btnSignUp">
               Εγγραφή 
              {inProccess?<FontAwesomeIcon icon="fa-solid fa-spinner" spin />:'' }
            </button>
            <button className='login-btn cancel'  onClick={(e)=>cancelSignUp(e)}>Ακύρωση εγγραφής και επιστροφή</button>
          </div>*/}
          <div className="info">{lang==="gb" ?``:`Τα πεδία με `}( <span>*</span> ) {lang==="gb"?` Mandatory fields`:` είναι υποχρεωτικά`}</div>
          <button name="btnSignUp" disabled={inProccess} type="submit" className='login-btn'  id="btnSignUp">
              {lang === "gb" ? `Register`:`Εγγραφή`} 
              {inProccess?<FontAwesomeIcon icon="fa-solid fa-spinner" spin />:'' }
          </button>
          <p>
            <button className='link-like' onClick={(e)=>cancelSignUp(e)}>{lang==="gb" ? `Cancel registration and return`:`Άκυρωση εγγραφής και επιστροφή`}</button>
          </p>
        </form>
      </section>
    )
  }else if (loginStatus=== CP){
    return(
      <section>
        <h2>{lang==="gb"?`Change password`:`Αλλαγή κωδικού πρόσβασης`}</h2>
        <form className='login-frm' onSubmit={(e)=>handleChangePass(e)}>
          <div ref={errRef} className={errMsg ? "err-msg":"no-err"}>{multilineError(errMsg)}</div>
          <h3>{user}</h3>
          <label htmlFor='pwd' className='mandatory'>Password <FontAwesomeIcon icon={['fas', showPass ? 'eye-slash':'eye']} className="icons pwd-icon" onClick={()=>toggleShowPass()} /></label>
          <input type={showPass ? 'text' : 'password'} ref={pwdRef} id="pwd" value={passwd||''} onChange={(e)=>setPasswd(e.target.value)}/>
          <div className="info">{lang==="gb" ?``:`Τα πεδία με `}( <span>*</span> ) {lang==="gb"?` Mandatory fields`:` είναι υποχρεωτικά`}</div>
          <button name="btnSignUp" disabled={inProccess} type="submit" className='login-btn'  id="btnSignUp">
              {lang === "gb" ? `Register`:`Εγγραφή`} 
              {inProccess?<FontAwesomeIcon icon="fa-solid fa-spinner" spin />:'' }
          </button>
          <p>
            <button className='link-like' onClick={(e)=>cancelSignUp(e)}>{lang==="gb"?`Cancel and return`:`Άκυρωση και επιστροφή`}</button>
          </p>
        </form>
      </section>
    )
  }else if (loginStatus=== EVF || loginStatus === EVFCP){
    return(
      <section>
        <h2>{lang==="gb" ? `e-mail verification`:'Επιβεβαίωση e-mail'}</h2>
        <form className='login-frm' onSubmit={(e)=>handleMailVerification(e)}>
          <div ref={errRef} className={err?"err-msg":"no-err"}>{multilineError(errMsg)}</div>
          
          <label htmlFor='verificationCode'>{lang === 'gb'? 'Verification code':'Κωδικός Επιβεβαίωσης'}</label>
          <span>{lang==="gb"?`for ${user} mail address`: `για το e-mail: ${user}`}</span>
          <input type="text" name="verificationCode"  id="verificationCode" value={verificationCode} onChange={(e)=>setVerificationCode(e.target.value)}/>
          {!resendMailRequest
          ? 
          <button name="btnVfMail" disabled={inProccess} type="submit" className='login-btn'  id="btnVfMail">
            {lang==='gb'?'Verify':'Επιβεβαίωση'} {inProccess?<FontAwesomeIcon icon="fa-solid fa-spinner" spin />:'' }
          </button>
          :''
          }
          <p>
            {lang==="gb"?`If you do not see the e-mail in a few minutes`
            :`Αν δεν έχετε λάβει το e-mail με τον κωδικό επιβεβαίωσης`}<br/>
            {lang==="gb"?`check your "junk mail" folder or "spam" folder.`
            :`ελέγξετε το φάκελο με τα ανεπιθύμητα μηνύματα (spam).`}<br />
            {lang==="gb"?`You can ask for re-send e-mail by clicking `:`Μπορείτε να ζητήσετε νέα αποστολή πατώντας `}
            <button type="button" disabled={inProccess} className='link-like' onClick={(e)=>reSendMail(e,loginStatus)}>
            {lang==="gb"?`here`:`εδώ`} {inProccess && resendMailRequest ?<FontAwesomeIcon icon="fa-solid fa-spinner" spin />:'' }
            </button>
          </p>
          <p>
          <button className='link-like' onClick={(e)=>cancelSignUp(e)}>{loginStatus===EVF?lang==="gb"?'Cancel registration and return':'Άκυρωση εγγραφής και επιστροφή':lang==="gb"? 'Cancel and return':'Ακύρωση και επιστροφή'}</button>
          </p>
        </form>
      </section>
    )
  }

}

export default Login