import React, {useState, useEffect, useRef} from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'
import OptionItem from './OptionItem'
import '../style/option.css'

function Options(props) {
    const timerID = useRef(null)
    const axiosPrivate = useAxiosPrivate()
    const {setAuth} = useAuth()

    const SOPT = "a8e0a01c2cf83d9d1213e971bd9b5bcd" // SearchOPTions
    const UPDOPT = "3235e102f1183fb6775454351793a8c8" // UPDateOPTion
    const DELOPT = "760ff9c42dd3266024fe4b219c7bef8e" // DELeteOPTion
    
    const noOptionsText="Δεν υπάρχουν επιλογές"
    const optionList = [
        {value:"work_number_id", label:"Αριθμός έργου"},
        {value:"publisher_id", label:"Εκδότης"},
        {value:"subject_id", label:"Θέμα"},
        {value:"format_id", label:"Μορφότυπο"},
        {value:"instrument", label:"Μουσικά όργανα"},
        {value:"description_id", label:"Περιγραφή"},
        {value:"poet", label:"Ποιητής / στιχουργός"},
        {value:"composer", label:"Συνθέτης"},
        {value:"work_title_id", label:"Τίτλος έργου"},
        {value:"series_title_id", label:"Τίτλος σειράς"}
    ]
    const defaultInfo = {err:0, msg:'', aff:0}

    const [searchOption, setSearchOption] = useState(null)
    const [searchField, setSearchField]=useState("")
    const [options, setOptions] = useState([])
    const [loading, setLoading] =useState(false)
    const [editMode, setEditMode] = useState({edit:false,progress:false})
    const [info, setInfo] = useState(defaultInfo)
   

      const optionResultList= options.filter(f=>{
      if(f.id === 0){
        return true
      }
      const translate={ά:'α', έ:'ε',ή:'η',ί:'ι',ϊ:'ι',ΐ:'ι',ό:'ο',ύ:'υ',ϋ:'υ',ΰ:'υ', ώ:'ω',ς:'σ', ü :'u', é:'e', ä:'a'}
      const pattern = /[άέήίϊΐόύϋΰώςüéä]/g
      const optionStr = f.label.trim().toLowerCase().replace(pattern, match=>translate[match])
      const inputStr = searchField ? searchField.trim().toLowerCase().replace(pattern, match=>translate[match])   :''
      
      return (inputStr ?  optionStr.includes(inputStr) :true)
  }).map((opt,i)=>{
   // console.log(opt)
     return (<OptionItem item={opt} action={action} edit={editMode} i={i} key={`optI_${i}`}/> )
   
  }) ||[] 

    const customStyles = {
        control: (base, state) => ({
            ...base,
            width:'100%',
            border:'1px solid var(--sec-color)',
            boxShadow:0,
            '&:hover':{
                borderColor:'var(--sec-color)',
              }
        }),
        menu: base =>({
            ...base,
            width:'100%', 
            border:'1px solid var(--sec-color)',
            //border:'1px solid #555555',
            outline:'none',
            boxShadow:'none',
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
              backgroundColor: isDisabled
                ? undefined
                : isSelected
                ? 'var(--pr-color)'
                : isFocused
                ? 'rgb(179, 126, 172,0.25)'
                : undefined,
                color: isDisabled
                ? undefined
                : isSelected
                ? '#fff':undefined,
              cursor: isDisabled ? 'not-allowed' : 'default',
            }
          }
      }

    function getClass(err=0, aff=0){
      if(err > 0){
        return "err"
      }else if(err===0 && aff===0){
        return "no-act"
      }else if(err===0 && aff  > 0){
        return "suc"
      }
      return ""
    }
    function  filterOptionFull (option, inputValue){
        //console.log(option)
        const translate={ά:'α', έ:'ε',ή:'η',ί:'ι',ϊ:'ι',ΐ:'ι',ό:'ο',ύ:'υ',ϋ:'υ',ΰ:'υ', ώ:'ω',ς:'σ', ü :'u', é:'e', ä:'a'}
        const pattern = /[άέήίϊΐόύϋΰώςüéä]/g
        const optionStr = option.data.label.trim().toLowerCase().replace(pattern, match=>translate[match])
        const inputStr = inputValue ? inputValue.trim().toLowerCase().replace(pattern, match=>translate[match]) :''
        //console.log(option)
        return inputValue ? optionStr.includes(inputStr) : true
      }

      function handleSelectChange(v){
       
         if(v && searchOption===v.value){
          return
         } 
        setSearchField("")
        setOptions([])
        setSearchOption(v?.value||null)
      }

       function action(actionType='', option=false){
        //actionType : 'edit', 'update', 'cancel' 'delete'
        //console.log('type', actionType, 'option', option)
        if(actionType==='edit'){
          setEditMode(prev=>({...prev, edit:option?.id}))
        }else if(actionType==='cancel'){
          console.log("option:", option);
          if(option?.id===0){
            const tmp = options.filter(o=>o.id!==option?.id)
            setOptions(tmp)
          }
          setEditMode(prev=>({...prev, edit:false}))
        }else if(actionType==='update'|| actionType==='delete'){
          
          setEditMode(prev=>({...prev, progress:true}))
          axiosPrivate.post("/documents.php",{searchOption, option , act: actionType ==='delete'? DELOPT :UPDOPT})
          .then(response=>{
              const {accessToken,options, error, message, affected} = response.data
              setOptions(options)
              setAuth(prev=>({...prev,accessToken }))
              setInfo({err:error, msg:message, aff:affected})
          })
          .catch(err=>{
            console.log(err)
            const {error, message, affected} = err?.response?.data
            setInfo({err:error, msg:message, aff:affected})
          })
          .finally(()=>{
              timerID.current = setTimeout(()=>{
                setInfo(defaultInfo)
              },1000)
            setEditMode({edit:false, progress:false})
          })  
        }
      }

     /*  function action(actionType='', option=false){
        return
      }
     */
      
      

      function addNewOption(obj){
        //window.scrollTo(0, document.body.scrollHeight);
        const objArr = ['composer', 'poet']
        const option = objArr.includes(obj) 
        ? {
            id:0,
            surname_gr:null,
            first_name_gr:null,
            des_gr:null,
            surname_gb:null,
            first_name_gb:null,
            des_gb:null,
            label:'',
            obj
          }
        : {
          id:0,
          des_gr:null,
          des_gb:null,
          field:searchOption || null,
          label:'',
          obj
        }
        //console.log("new: ", option)
        setEditMode(prev=>({...prev, edit:0}))
        setOptions(prev=>[...prev, option])
      }

     
      
      useEffect(() => {
        if(searchOption){
            setOptions([])
            setLoading(true)
            axiosPrivate.post("/documents.php",{searchOption , act:SOPT})
            .then(response=>{
                const {accessToken, options} = response.data
                setOptions(options)
                setAuth(prev=>({...prev,accessToken }))
            })
            .catch(err=>console.log(err)
            )
            .finally(()=>setLoading(false)) 
        }
      }, [searchOption, setAuth, axiosPrivate])
      
      useEffect(() => {
        const timer = timerID.current
      return () => {
        clearTimeout(timer)
      }
    }, [])

     
    
  return (
    <main>
      <ul className='doc-list-bar'>         
          <li><Link to=".." className='usr-lnk'><FontAwesomeIcon icon="fa-solid fa-turn-up" flip="horizontal" /> Διαχείριση</Link></li>
          <li>Διαχείριση επιλογών</li>    
          { 
            info.msg 
            ?
          <li className={`opt-info-box ${getClass(info.err, info.aff)}`}>{info.msg}</li>
          :''}
      </ul>

      <div className='site-path'>
          <ul className='search-container'>
              <li>
                  <label>Πεδίο</label>
                  <Select 
                      name='searchOption' 
                      id='searchOption_id'
                      options={optionList} 
                      isClearable={true}
                      isMulti={false}
                      value={optionList.find(o=>o.value === searchOption)||null}
                      onChange={v=>handleSelectChange(v)}
                      noOptionsMessage={()=>noOptionsText}
                      menuPlacement='auto'
                      filterOption = {filterOptionFull}    
                      styles={customStyles}
                      placeholder={''}
                      isDisabled={editMode.progress || editMode.edit===false ? false:true}
                  />
                  {loading ? <FontAwesomeIcon icon="fa-solid fa-spinner" spin className='spinner'/>:''}
              </li>
              
          </ul>
      </div>
      {!loading ?
        
      <div>
        <div>
          <input type='text' className='opt-srch-inp' name='searchField' value={searchField} onChange={(e)=>{setSearchField(e.target.value)}} />
          <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className='opt-srch-icon'/>
        </div>
          {optionResultList.length? <div className='summary'>{`Βρέθηκαν: ${optionResultList.length} εγγραφές`}</div>:'' }
          {optionResultList}
      
      
        <button className='opt-action' onClick={()=>addNewOption(searchOption)} disabled={searchOption && editMode.edit===false ?false : true}>
          <FontAwesomeIcon icon="fa-solid fa-plus-circle"/>
          <div style={{fontSize:'0.8rem', fontWeight:'normal', padding:'0.5rem 0 0 0'}}>Νέo</div> 
        </button>
      </div>
      :''} 
    </main>
  )
}

export default Options