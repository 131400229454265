import React, {useEffect, useState} from 'react'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios,{domainName} from '../api/axiosapi'
import useAuth from '../hooks/useAuth'
import {getPageText} from '../utils/util'
import '../style/archive.css'
//import Select, {components} from 'react-select'
import '../utils/fontawesome'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//import useAuth from '../hooks/useAuth';

function Archive() {
  const {lang} = useAuth()
  const DATA_URL="fetchdata.php"
  const [doc, setDoc] = useState([])
  const [pic, setPic] = useState([])

  const content =  getPageText('archive')

  useEffect(() => {
    async function getContents(){ 
      try {
        const response= await axios.post(DATA_URL, {type:'archive',lang})
        
        const {archive_doc,archive_pic } = response.data
        setDoc(archive_doc)
        //console.log('options',options )
        setPic(archive_pic)
        
      }catch(err){
        const {error, message} =err?.response?.data
        console.log(error , message)
      }
    }
    getContents()
    
  }, [lang])
  
  const  caruselData = pic.map((p,i) =>{
    //const base = process.env.PUBLIC_URL
    return(
      <div key={`ci_${i}`}>
          <img src={`${domainName}/${p.pic}`} alt={`pic_${i+1}`}/>
      </div>
    )
  })

  const documentContent = doc.map((d,i)=>
    d.is_title ? 
     <h3 key={`t_${i}`}>
       {d.paragraph}
     </h3>
     :
     <p key={`p_${i}`}>
       {d.paragraph}
     </p>

  )
   
  
 // console.log("PUBLIC_URL ", process.env.PUBLIC_URL)
 //console.log(content[lang])
 //console.log(lang)
  return (
    <main>
      <div className='t-s'>
        <h2>{content[lang].PageTitle}</h2>
        <h3>{content[lang].pageSubTitle}</h3>
      </div>
      <div className='main-content'>
        {pic.length > 0 ?
        <Carousel autoFocus={true} autoPlay={true} infiniteLoop={true} showThumbs={false} className='carusel'>
            {/*
              <div>
              <img src={process.env.PUBLIC_URL +"/icons/m5.jpg"} alt="test  2" />
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + "/icons/m4.jpg"} alt="test  1"/>
            <p className="legend" >Legend 1</p>
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + "/icons/m1.jpg"} alt="test  1"/>
            <p className="legend" >Legend 1</p>
            </div>
            <div>
              <img src={process.env.PUBLIC_URL + "/icons/m2.jpg"} alt="test  2" />
            
            </div>
            
            <div>
                <img src={process.env.PUBLIC_URL +"/icons/m6.jpg"} alt="test  3"/>
                <p className="legend">Legend 1</p>
            </div>
            */}
            {caruselData}
        </Carousel>
        :''
        }
        <div className='article-container'>
            <div className='t-L'>
                <h2>{content[lang].PageTitle}</h2>
                <h3>{content[lang].pageSubTitle}</h3>
            </div>
            
            <article>
              {documentContent}
              
            </article>
            {/*console.log(process.env.PUBLIC_URL)*/}
            {/*console.log(doc)}
            {console.log(pic)*/}
        </div>
      </div>
    
    </main>
  )
}

export default Archive