import React from 'react'
import "../style/pagination.css"

export default function Pagination(props) {
    const {totalRows, rpp, currentPage} = props
    const groupPage=5
    const buttonsObj=[]

    const totalPages=Math.ceil(totalRows / rpp)
    const currentGroupPage=Math.ceil(currentPage/groupPage)
    //const totalGroupPages=Math.ceil(totalPages/groupPage)

    buttonsObj.push({symbol:"<<", value:1, disabled: currentPage===1?true:false, cn:null})
    const prevPage=currentPage === 1 ? 1:currentPage - 1
    buttonsObj.push({symbol:"<", value:prevPage, disabled:currentPage===1?true:false, cn:null})
    
    const prevGroupPage=(currentGroupPage -1 )*groupPage
    if (prevGroupPage > 0)  
        buttonsObj.push({symbol:"...", value:prevGroupPage, disabled:false,cn:null})

    for(let i = prevGroupPage+1;i<=prevGroupPage + groupPage; i++){
        if(i > totalPages)
            break
        else{
            buttonsObj.push({symbol:i, value:i, disabled:false,cn: i === currentPage? "current-page":null})
        }
    }

    const nextGroupPage = currentGroupPage * groupPage +1
    if(nextGroupPage <= totalPages) 
        buttonsObj.push({symbol:"...", value:nextGroupPage, disabled:false, cn:null})

    const nextPage=currentPage + 1 > totalPages ? totalPages : currentPage + 1
    buttonsObj.push({symbol:">", value:nextPage, disabled:currentPage>=totalPages?true:false, cn:null})

    buttonsObj.push({symbol:">>", value:totalPages, disabled:currentPage>=totalPages?true:false, cn:null})    
    const buttons = buttonsObj.map((b,i)=>
         
        <button type="button" key={`bp_${i}`} className={`pg ${b.cn}`} disabled={b.disabled} name="page" value={b.value||''} onClick={(e)=>props.changePage(e)}>{b.symbol}</button>
    )
    //console.log('t', totalPages, 'c', currentPage)
    return (
        <div className='button-container'>
        {buttons}
        </div>
    )
}
