import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserCheck, faUser, faUserLock, faBars, faXmark, faBookOpen, faBook, faEnvelope, faSpinner,faAngleUp, faAngleDown,
         faEye, faEyeSlash,faUsers, faUserPlus, faHouse, faAt, faMusic, faMagnifyingGlass, faGear,faFilePdf,
         faTurnUp, faEraser, faKey, faCheck, faRotateLeft, faRightFromBracket, faChevronUp, faChevronDown,faLock,
         faCircleXmark, faTrashAlt, faCirclePlus, faPenToSquare, faPlus, faFileZipper} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram} from '@fortawesome/free-brands-svg-icons'

library.add(
    faUserCheck,
    faUser,
    faUserLock,
    faUsers,
    faUserPlus,
    faBars,
    faXmark,
    faBookOpen,
    faBook,
    faFacebook,
    faInstagram,
    faEnvelope,
    faSpinner,
    faAngleUp,
    faAngleDown,
    faEye,
    faEyeSlash,
    faHouse,
    faAt,
    faMusic,
    faMagnifyingGlass,
    faGear,
    faTurnUp,
    faEraser,
    faKey,
    faCheck,
    faRotateLeft,
    faRightFromBracket,
    faChevronUp, 
    faChevronDown,
    faCircleXmark,
    faTrashAlt,
    faCirclePlus,
    faPenToSquare,
    faPlus,
    faFilePdf,
    faLock,
    faFileZipper
)