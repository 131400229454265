import React,{useState} from 'react'
import {country2emoji2} from '../utils/util'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function FormOption(props) {

    const {obj, field, addOption, cancelOption,optFrmState}=props
    //console.log(props)
    const [option, setOption]=useState({obj,field})
    function handleChange(ev){
        const {name, value} = ev.target
        setOption(prev=>({...prev, [name]:value||null}))
    }

    function handleChangeWN(ev){
        const {name, value} = ev.target
        setOption(prev=>({...prev, [name]:value||null,des_gb:value||null}))
    }
    if(obj==='options' && field==='work_number_id'){
        return (
            <div className='option-form'>
                
                <input type='text' name="des_gr" className='option-des'  onChange={(e)=>handleChangeWN(e)}/>
                {!optFrmState
                ?
                <div style={{marginTop:'0.75rem'}}>
                    <button className='cancel-option' onClick={()=>cancelOption()}>
                        <FontAwesomeIcon icon="fa-solid fa-xmark" />
                    </button>
                    <button className='submit-option' onClick={(e)=>addOption(option, e)}>
                        <FontAwesomeIcon icon="fa-solid fa-check" />
                    </button>
                </div>
                :
                <FontAwesomeIcon icon="fa-solid fa-spinner" spin />
                }
            </div>
        )

    }else if(obj==='options'){
        return (
            <div className='option-form'>
                <span className='flag-lang'>{country2emoji2('GR')}</span>
                <input type='text' name="des_gr" className='option-des' onChange={(e)=>handleChange(e)}/>
                <span className='flag-lang'>{country2emoji2('GB')}</span>
                <input type='text' name="des_gb" className='option-des' onChange={(e)=>handleChange(e)}/>
                {!optFrmState
                ?
                <div style={{marginTop:'0.75rem'}}>
                    <button className='cancel-option' onClick={()=>cancelOption()}>
                        <FontAwesomeIcon icon="fa-solid fa-xmark" />
                    </button>
                    <button className='submit-option' onClick={(e)=>addOption(option, e)}>
                        <FontAwesomeIcon icon="fa-solid fa-check" />
                    </button>
                </div>
                :
                <FontAwesomeIcon icon="fa-solid fa-spinner" spin />
                }
            </div>
        )
    }else if (obj==='instrument'){
        return (
            <div className='option-form'>
                <span className='flag-lang'>{country2emoji2('GR')}</span>
                <input type='text' name="instrument_gr" className='option-des' onChange={(e)=>handleChange(e)}/>
                <span className='flag-lang'>{country2emoji2('GB')}</span>
                <input type='text' name="instrument_gb" className='option-des' onChange={(e)=>handleChange(e)}/>
                {!optFrmState
                ?
                <div style={{marginTop:'0.75rem'}}>
                    <button className='cancel-option' onClick={()=>cancelOption()}>
                        <FontAwesomeIcon icon="fa-solid fa-xmark" />
                    </button>
                    <button className='submit-option' onClick={(e)=>addOption(option, e)}>
                        <FontAwesomeIcon icon="fa-solid fa-check" />
                    </button>
                </div>
                :
                <FontAwesomeIcon icon="fa-solid fa-spinner" spin />
                }
            </div>
        )
    }else if(obj==='poet' || obj==='composer'){
        return(
        <div className='option-form'>
            <div style={{paddingLeft:'1.5rem'}}>
                <label className='option-lbl'>Επώνυμο:</label>
                <label className='option-lbl'>Όνομα:</label>
            </div>
            <div>
                <span className='flag-lang'>{country2emoji2('GR')}</span>
                <input type='text' name="surname_gr" className='option-name' onChange={(e)=>handleChange(e)}/>
                <input type='text' name="first_name_gr" className='option-name' onChange={(e)=>handleChange(e)}/>
                (<input type='text' name="des_gr" className='option-name' onChange={(e)=>handleChange(e)}/>)
            </div>
            <div style={{paddingLeft:'1.5rem'}}>
                <label className='option-lbl'>Surname:</label>
                <label className='option-lbl'>First Name:</label>
            </div>
            <div>
                <span className='flag-lang'>{country2emoji2('GB')}</span>
                
                <input type='text' name="surname_gb" className='option-name' onChange={(e)=>handleChange(e)}/>
                <input type='text' name="first_name_gb" className='option-name' onChange={(e)=>handleChange(e)}/>
                (<input type='text' name="des_gb" className='option-name' onChange={(e)=>handleChange(e)}/>)
            </div>
            {!optFrmState
            ?
            <div style={{marginTop:'0.75rem'}}>
                <button className='cancel-option' onClick={()=>cancelOption()}>
                    <FontAwesomeIcon icon="fa-solid fa-xmark" />
                </button>
                <button className='submit-option' onClick={(e)=>addOption(option, e)}>
                    <FontAwesomeIcon icon="fa-solid fa-check" />
                </button>
            </div>
            :
            <FontAwesomeIcon icon="fa-solid fa-spinner" spin />
            }
        </div>
        )
    }
}

export default FormOption