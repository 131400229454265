import React, {useState,useEffect,useRef} from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'
import { daysDiff, checkUserStatus, randomPass } from '../utils/util'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import '../style/user.css'

function User(props) {
    const USR = "84731661fb99d22676b973ce11d1c373"
    const UPD="4ee9e542137bb23a5724356f9b8ba381"
    const MAX_PASS_LEN = 15
    const MIN_PASS_LEN = 6
    const timerID = useRef(null)
    const navigate = useNavigate()
    const {uid}= useParams()
    const axiosPrivate = useAxiosPrivate()
    const {setAuth} = useAuth()

    
    const defaultInfo = {msg:'', cl:''}

    const [user, setUser]=useState(null)
    const [passLen, setPassLen]= useState(6);
    const [newGeneratedPass, setNewGeneratedPass] = useState('')
    const [defaultPass, setDefaultPass] = useState(null)
    const [remark, setRemark] = useState(false)
    const [showPass, setShowPass] = useState(false)
    const [sendPass, setSendPass] = useState(uid==='new')
    const [sendExpInfo, setSendExpInfo] = useState(uid==='new')
    const [inProgress, setInProgress] = useState(false)
    const [isDirty, setIsDirty] = useState(false)
    const [info, setInfo] = useState(defaultInfo)
    //console.log(user?.expire_date)
    const diff = daysDiff(user?.expire_date) 
    const statusClassName = checkUserStatus(diff, user?.connection_status,user?.is_admin) 

    function reNew(expDate, ev){
      
        const [y, m , d] = expDate 
        ? expDate.split('-').map((dt,i)=> i===1?parseInt(dt)-1:parseInt(dt)) 
        :new Date().toLocaleDateString('fr-CA').split('-').map((dt,i)=> i===1?parseInt(dt)-1:parseInt(dt)) 

        const newDate = new Date(y+2,m,d).toLocaleDateString('fr-CA')
        //console.log(newDate)
        setUser(prev=>({...prev, expire_date:newDate}))
        setIsDirty(true)
       
    }

    function handleInputChange(ev){
        const {name, value} = ev.target
        if(name === 'passLen'){
            setPassLen(value)
        }else{
            setUser(prev=>({...prev, [name]:value||null}))
            setIsDirty(true)
        }
    }

    function handleOnOff(ev){
        const {name} = ev.target 
        setUser(prev=>({...prev, [name]:prev[name]===1?0:1}))
        setIsDirty(true)
    }

    function clearExpDate(){
        setUser(prev=>({...prev,expire_date:null}))
        setIsDirty(true)
    }

    function newPassword(pass, rst=false){ //reset pass
        if(newGeneratedPass || rst){
            setRemark('remark')
            timerID.current=setTimeout(()=>{
                setRemark(false)
            },500)
            setUser(prev=>({...prev,user_pass:pass}))
            setNewGeneratedPass('')
            if(!rst){
                setIsDirty(true)
            }
        }
    }

    function toggleShowPass(){
        setShowPass(prev=>!prev)
    }

    function checkFields(){
        //console.log(user)
        const {user_name, user_pass, contact_email} = user
        const errorTbl=[]
        const emailPattern=/^([a-z0-9+_\-?&]+)(\.[a-z0-9+_\-?&]+)*@([a-z0-9-]+\.)+[a-z]{2,6}$/i
        //const p=/^([a-z0-9\+_\-\?\&]+)(\.[a-z0-9\+_\-\?\&]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/gi
        const passPattern = /^(?=.*\d)(?=.*[a-zA-Z])(?!.* )(?=.*[^a-zA-Z0-9]).{6,15}$/mi
        if(!emailPattern.test(user_name)){
            errorTbl.push('Το όνομα χρήστη δεν έχει έγκυρη μορφή')
        }
        if(contact_email && !emailPattern.test(contact_email)){
            errorTbl.push('Το e-mail Επαφής δεν έχει έγκυρη μορφή')
        }
        if( user_pass && !passPattern.test(user_pass)){
            errorTbl.push('Το password δεν έχει έγκυρη μορφή... \n ' +
            'Το password πρέπει να έχει μήκος απο 6 έως 15 χαρακτήρες.\n' +
            'Θα πρέπει να περιλαμβάνει:\n'+
            '- 1 ή περισσότερα λατινικά γράμματα\n'+
            '- 1 ή περισσότερα αριθμητικά ψηφία\n'+
            '- 1 ή περισσότερα σύμβολα')
        }
        
        return errorTbl.length > 0 ? errorTbl.join('\n'):''
    }

    function multilineError(err){
        return err.split('\n').map((item, i )=>(<div key={`er_${i}`}>{item}</div>))
    }
    
    function updateUser(ev){
        if(isDirty || sendPass || sendExpInfo){
            //setInProgress(true)
            const err = checkFields()
            if(err){
                setInfo({msg:err,cl:'err-msg'})
            }else{
                setInProgress(true)
                axiosPrivate.post("/users.php",{user,sendPass,sendExpInfo,act:UPD})
                .then(response=>{
                    const {userID, affected,message, accessToken} = response.data
                    const cl=affected?'suc-msg':'inf-msg'
                    setUser(prev=>({...prev, id:userID}))
                    setInfo({msg:message,cl:cl})
                    setAuth(prev=>({...prev,accessToken }))
                })
                .catch(err=>{
                    const {message}=err?.response?.data
                    setInfo({msg:message,cl:'err-msg'})
                })
                .finally(()=>{
                    setInProgress(false)
                    timerID.current = setTimeout(()=>{
                        setInfo(defaultInfo)
                    },3000)
                })
            }
        }
    }
   
    
    useEffect(() => {
      const pattern = /^[1-9][0-9]*$/g;
      window.scrollTo(0,0)
      if(pattern.test(uid)){
        axiosPrivate.post("/users.php",{id:uid,act:USR})
        .then(response=>{
            const {accessToken, user} = response.data
            setAuth(prev=>({...prev,accessToken }))
            setUser(user)
            setDefaultPass(user?.user_pass||null)
        })
        .catch(err=>{
            console.log(err)
            setInfo({msg:"Bad URL", cl:"err-msg"})
        })
      }else if(uid==='new'){
        const blankUser = {
            id: 'new',
            user_name: null,
            user_pass: null,
            surname: null,
            first_name: null,
            tel: null,
            contact_email: null,
            expire_date:null,
            expire:null,
            is_admin:0,
            connection_status:1
        }
        setUser(blankUser)
      }else {
        setInfo({msg:"Bad URL", cl:"err-msg"})
      }

    }, [uid, axiosPrivate, setAuth])

    useEffect(() => {
        const timer = timerID.current
      return () => {
        clearTimeout(timer)
      }
    }, [])

  if(user)
  return (
    <main>
        <div className={`user-title ${statusClassName?.clf}`}>{user?.id==='new' ? 'Νέος Χρήστης' :`ID: ${user.id}` } {user?.is_admin?<FontAwesomeIcon icon={['fas', 'gear']} style={{color:'var(--sec-color'}}/>:''}</div>
        <div className='form-container'>
            <div>
                <label htmlFor='user_name'>Όνομα χρήστη / username</label>
                <input className='user-form' type="text" name="user_name" id="user_name" value={user.user_name || ''} onChange={(ev)=>{handleInputChange(ev)}} />
                
                <label htmlFor='user_pass'>Κωδικός Πρόσβασης / password
                    <FontAwesomeIcon icon={['fas', 'rotate-left']} className="frm-eye-icon" onClick={()=>newPassword(defaultPass, true)}/>
                </label>
                <input className={`user-form-pass ${remark||''}`} type={showPass ? "text" : "password"} name="user_pass" id="user_pass" value={user.user_pass || ''} onChange={(ev)=>{handleInputChange(ev)}} />
                {/*<FontAwesomeIcon icon={['fas', showPass ? 'eye-slash':'eye']} className="icons pwd-icon" onClick={()=>toggleShowPass()} /></label>*/}
                <FontAwesomeIcon icon={['fas', showPass ? 'eye-slash':'eye']} className="frm-eye-icon" onClick={()=>toggleShowPass()} />

                <div className='pass-tool'>
                    <input 
                        type="range" 
                        className='slider-range' 
                        name="passLen" 
                        value={passLen} 
                        id="passLen" 
                        min={MIN_PASS_LEN} 
                        max={MAX_PASS_LEN}
                        onChange={(ev)=>handleInputChange(ev)}
                    />
                    
                    <label htmlFor='passLen' className='slider-range-label-inline'>{passLen}</label>
                    <FontAwesomeIcon icon={['fas', 'key']} className="frm-key-icon" onClick={()=>setNewGeneratedPass(randomPass(passLen))}/>
                
                    <label className='created-pass'>{newGeneratedPass}</label>
                    
                    {newGeneratedPass ?
                    <>
                        <FontAwesomeIcon icon={['fas', 'xmark']} className="frm-key-icon" style={{padding: '0.2rem 0.7rem'}} onClick={()=>setNewGeneratedPass('')}/>
                        <FontAwesomeIcon icon={['fas', 'check']} className="frm-key-icon"  onClick={()=>{newPassword(newGeneratedPass, false)}}/>
                    </>
                    :''}
                    {/* {<FontAwesomeIcon icon={['fas', 'rotate-left']} className="frm-key-icon" onClick={()=>newPassword(defaultPass, true)}/>} */}
                </div>
                <label htmlFor='expire_date'>Ημερομηνία Λήξης Συνδομής / expire date</label>
                <input className='user-form-date' type="date" name="expire_date" id="expire_date" value={user.expire_date || ''} onChange={(ev)=>{handleInputChange(ev)}} /> 
                <FontAwesomeIcon icon={['fas', 'xmark']} className="frm-key-icon" style={{position: 'relative', top:'0.3rem',padding: '0.2rem 0.7rem'}} onClick={()=>{clearExpDate()}}/>
                <button className='link-like-btn' style={{margin:0,padding:'0.2rem 0.5rem'}} onClick={(e)=>reNew(user.expire_date, e)}>+2</button>

            </div>
            <div>
                <label htmlFor='surname'>Επώνυμο / surname</label>
                <input className='user-form' type="text" name="surname" id="surname" value={user.surname || ''} onChange={(ev)=>{handleInputChange(ev)}} />

                <label htmlFor='first_name'>Όνομα / first name</label>
                <input className='user-form' type="text" name="first_name" id="first_name" value={user.first_name || ''} onChange={(ev)=>{handleInputChange(ev)}} />

                <label htmlFor='tel'>Τηλέφωνο / phone number</label>
                <input className='user-form' type="text" name="tel" id="tel" value={user.tel || ''} onChange={(ev)=>{handleInputChange(ev)}} />
                
                <label htmlFor='contact_email'>E-mail Επαφής / contact e-mail</label>
                <input className='user-form' type="text" name="contact_email" id="contact_email" value={user.contact_email || ''} onChange={(ev)=>{handleInputChange(ev)}} />

            </div>
           <div>
            <label>Αποστολή e-mail στο χρήστη γιά:</label>
                <ul className='mail-opt-list'>
                    <li>
                        <label className='lbl-chk' style={{fontSize:'1rem'}}>
                            Κοινοποίηση κωδικού πρόσβασης
                            <input type="checkbox" name="sendPass" checked={sendPass} onChange={()=>setSendPass(prev=>!prev)}/>
                            <span className='checkmark'></span>
                        </label>
                    </li>
                    <li>
                        <label className='lbl-chk' style={{fontSize:'1rem'}}>
                            Ενεργοποίηση / Ανανέωση συνδρομής
                            <input type="checkbox" name="sendExpInfo" checked={sendExpInfo} onChange={()=>setSendExpInfo(prev=>!prev)} />
                            <span className='checkmark'></span>
                            
                        </label>
                    </li>
                    <li style={{marginTop:'2rem'}}>
                        <label>Κατάσταση Λογαριασμού</label>
                        <span style={{color:!user?.connection_status?'red':'#555'}}>{user?.connection_status?'Ενεργός':'Ανενεργός'}</span>
                        <label className="switch" style={{float:'right'}}>
                            <input type="checkbox" name='connection_status' checked={user?.connection_status||false} onChange={(e)=>{handleOnOff(e)}}/>
                            <span className="slider round"></span>
                        </label>
                        
                    </li>
                    <li style={{marginTop:'2rem'}}>
                        <label>Λογαριασμός Διαχειριστή ?</label>
                        <span style={{color:user?.is_admin?'var(--sec-color)':'#555',fontWeight:'bold'}}>{user?.is_admin?'Διαχειριστής':'Όχι'}</span>
                        <label className="switch" style={{float:'right'}}>
                            <input type="checkbox" name='is_admin' checked={user?.is_admin||false} onChange={(e)=>{handleOnOff(e)}}/>
                            <span className="slider round"></span>
                        </label>
                        
                    </li>
                </ul>
           </div>
            
        </div> 
        <div className={info?.cl||''} onClick={()=>setInfo(defaultInfo)}>
            {info?.msg ?multilineError(info?.msg):''}
        </div>

        
        <div>
            <button  style={{width:'auto',padding:'0.5rem 1rem'}} disabled={inProgress} onClick={(e)=>updateUser(e)}>
                {inProgress ?<FontAwesomeIcon icon="fa-solid fa-spinner" spin />:''}
                Ενημέρωση
                {
                    sendPass || sendExpInfo ? 
                    <FontAwesomeIcon icon={['fas', 'envelope']} style={{marginLeft:'0.5rem'}}/>
                    :''
                }
            </button>
            <button className='link-like-btn' onClick={()=>{navigate(-1, { replace: true })}}>Επιστροφή</button>
        </div>
    </main>
  )
  else 
    return ( 
         !info.msg ?
          <div>Load...</div>
         :
         <div className={info.cl}>BAD URL</div>
        
    )
}

export default User