import React,{useEffect, useState, useRef} from 'react'
import '../utils/fontawesome'
import { country2emoji2 } from '../utils/util'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link} from 'react-router-dom'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'
import UploadReduse from './UploadReduse'
import "../style/adminarchive.css"

function AdminArchive() {
    const GAV = "73700756ecdf0becf21037afb32380f5"; // Get ArchiVe
    const UDAV = "3eb81dcb4c88a5ef175d5856762c149a"; //UpDate ArchiVe
    const UDFLAV = "c32efe9fb3bf90fa74ff994da254aa21" // UpDate FiLe ArchiVe

    const timerID = useRef(null)
    const axiosPrivate = useAxiosPrivate()
    const {setAuth} = useAuth()

    const defaultInfo = {msg:'', cl:''}
    const defaultInfoFiles = {msg:'', cl:''}
    const [loading, setLoading] = useState(false)
    const [inProgress, setInProgress] = useState(false)
    //const [inProgressFile, setInProgressFile] = useState(false)
    const [edit, setEdit] = useState(false)
    const [archive, setArchive]=useState([])
    const [uploadFiles, setUploadFiles] = useState([])
    const [info, setInfo] = useState(defaultInfo)
    const [infoFiles, setInfoFiles]=useState(defaultInfoFiles)


    useEffect(() => {
        const timer = timerID.current
      return () => {
        clearTimeout(timer)
      }
    }, [])

    useEffect(() => {
        setLoading(true)
        axiosPrivate.post("/admin.php",{act:GAV})
        .then(response=>{
            const {accessToken, archive, uploadFiles} = response.data
            setAuth(prev=>({...prev,accessToken }))
            setArchive(archive)
            setUploadFiles(uploadFiles)
        })
        .catch(err=>console.log(err)
        )
        .finally(()=>setLoading(false)) 
    }, [axiosPrivate, setAuth])

    function addParagraph(i){
        const newParagraph={id:null, 
                      lang:archive[i].lang, 
                      is_title:parseInt(archive[i].is_title), 
                      paragraph:null, 
                      start_date:null,
                      end_date:null
        }
        const newArchive = [...archive.slice(0,i+1), newParagraph, ...archive.slice(i+1)]
        setArchive(newArchive)
        setEdit(i+1)
    }
    function deleteParagraph(indx){
        const newArchive = archive.filter((a,i)=> i !== indx )
        setArchive(newArchive)
        setEdit(false)
    }
    function editParagraph(i){
        setEdit(i)
    }
    function handleChange(i, ev){
        const {name, value} = ev.target
        const currentParagraph = {...archive[i], [name]:value||null}
        const newArchive = [...archive.slice(0,i), currentParagraph, ...archive.slice(i+1)]
        //console.log(newArchive)
        setArchive(newArchive)

    }
    function returnFromEdit(){
        setEdit(false)
    }

    function appendFiles(arrFile){
        //console.log(arrFile)
        //setInProgressFile(true)
        axiosPrivate.post("/admin.php",{act:UDFLAV, files:arrFile})
        .then(response=>{
            const { uploadFiles , message, affected, accessToken} = response.data 
            setUploadFiles(uploadFiles)
            const cl=affected?'suc-msg':'inf-msg'
            setInfoFiles({msg:message,cl:cl})
            setAuth(prev=>({...prev,accessToken }))
            //console.log(message, affected , 'ok')

        })
        .catch(err=>{
            const {message}=err?.response?.data
            setInfoFiles({msg:message,cl:'err-msg'})
            //console.log(message)
        })
        .finally(()=>{
            //setInProgressFile(false)
            timerID.current = setTimeout(()=>{
                setInfoFiles(defaultInfoFiles)
            },3000)
        })
        //setUploadFiles(arrFile)

    }

    function multilineError(err){
        return err.split('\n').map((item, i )=>(<div key={`er_${i}`}>{item}</div>))
    }

    function saveDacument(ev){
        ev.preventDefault()
        //console.log(archive)
        setInProgress(true)
        axiosPrivate.post("/admin.php",{act:UDAV, archive})
        .then(response=>{
            const {message, accessToken, affected} = response?.data
            const cl=affected?'suc-msg':'inf-msg'
            setInfo({msg:message,cl:cl})
            setAuth(prev=>({...prev,accessToken }))
        })
        .catch(err=>{
            const {message}=err?.response?.data
            setInfo({msg:message,cl:'err-msg'})
        })
        .finally(()=>{
            setInProgress(false)
            timerID.current = setTimeout(()=>{
                setInfo(defaultInfo)
            },3000)
        })
    }

    const archiveContent = archive.map((a,i)=>{
       if (edit===i ){
        return (
            <div key={`kb_${i}`} className='paragraph'>
                <h4 className='p-title'>{`#${i+1}`}</h4>
                <select className='lang' name='lang' value={a.lang} onChange={(e)=>handleChange(i,e)}>
                    <option value="gr">{country2emoji2('gr')} Ελληνικά</option>
                    <option value="gb">{country2emoji2('gb')} English</option>
                </select>
                <select className='lang' name="is_title" value={a.is_title} onChange={(e)=>handleChange(i,e)}>
                    <option value={0}>Κείμενο παραγράφου</option>
                    <option value={1}>Τίτλος παραγράφου</option>
                </select>
                <textarea className='p-content' name='paragraph' value={a.paragraph||''} onChange={(e)=>handleChange(i,e)}/>
                
                <ul className='tool-bar-archive'>
                    <li>
                        <button className='ll-tool-bar' onClick={(e)=>returnFromEdit()}>
                            <FontAwesomeIcon icon="fa-solid fa-check" />
                        </button>
                    </li>
                    
                </ul>
            </div> 
        )
       }else{
         return (
            <div key={`kb_${i}`} className='paragraph'>
                <h4 className='p-title'>{`#${i+1}`} <span>{country2emoji2(a.lang)}</span></h4>
                {parseInt(a.is_title)===1?<h3>{a.paragraph}</h3>:<p key={`p_${i}`}>{a.paragraph}</p>}
                <ul className='tool-bar-archive'>
                    <li>
                        <button className='ll-tool-bar' onClick={(e)=>addParagraph(i)}>
                            <FontAwesomeIcon icon="fa-solid fa-circle-plus" />
                        </button>
                    </li>
                    <li>
                        <button className='ll-tool-bar' onClick={(e)=>editParagraph(i)}>
                            <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                        </button>
                    </li>
                    <li>
                        <button className='ll-tool-bar' onClick={(e)=>deleteParagraph(i)}>
                        <FontAwesomeIcon icon="fa-solid fa-trash-alt" />
                        </button>
                    </li>
                </ul>
            </div>
         )
        }
    })
    

  return (
    <main>
        <ul className='users-bar'>          
            <li><Link to=".." className='usr-lnk'><FontAwesomeIcon icon="fa-solid fa-turn-up" flip="horizontal" /> Διαχείριση</Link></li>
            <li>Αρχείο</li>
            <li className="r-pos">
                <select className='lang'>
                    <option value="all">Όλες</option>
                    <option value="gr">{country2emoji2('gr')} Ελληνικά</option>
                    <option value="gb">{country2emoji2('gb')} English</option>
                </select>
            </li>
        </ul>
        <div className={infoFiles?.cl||''} onClick={()=>setInfoFiles(defaultInfo)}>
                {infoFiles?.msg ?multilineError(infoFiles?.msg):''}
        </div>    
        {!loading ? 
        <div className='main-content-admin'>
           
            <UploadReduse 
                title="Μεταφόρωση Φωτογραφιών - Εικόνων" 
                droptext="Αφήστε εδώ τα αρχεία σας..."
                uploadFileList={uploadFiles}
                appendFiles={appendFiles}
            />
            
           
            
            
            <div className='article-container-admin'>
                <h3>Κείμενα Αρχείου Αθηναικής Μανδολινάτας</h3>
                <article>
                    {archiveContent}
                </article>
                <div className={info?.cl||''} onClick={()=>setInfo(defaultInfo)}>
                    {info?.msg ?multilineError(info?.msg):''}
                </div>
                <button name='btnSave' onClick={(e)=>{saveDacument(e)}}>
                    {inProgress ?<FontAwesomeIcon icon="fa-solid fa-spinner" spin />:''}
                    Ενημέρωση
                </button>
            </div> 
        </div>
        :<h1>Load...</h1>}
    </main>
  )
}

export default AdminArchive