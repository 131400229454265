import React, {createContext, useState} from 'react'

const AuthContext = createContext({})

export const  AuthProvider = ({children}) => {
    const [auth, setAuth] = useState({});
    const languages=['gr','gb']
    const language = localStorage?.getItem('lang') && languages.includes(localStorage.getItem('lang')) ?localStorage.getItem('lang'):'gr'
    localStorage.setItem('lang',language)
    const [lang, setLang] = useState(language);
    return (
        <AuthContext.Provider value={{auth, setAuth, lang, setLang}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;