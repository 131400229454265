import axios from '../api/axiosapi'
import useAuth from './useAuth'

const  useRefreshToken = () => {
    const REFRESH_URL = "refresh.php";
    const {setAuth} = useAuth();
    const refresh = async  () => {
        try{
            const response = await axios.get(REFRESH_URL, {
                withCredentials:true
            });
            setAuth(prev => {
                //console.log('prev',prev)
                //console.log(response.data.accessToken)
                return {
                    user:response.data.userID,
                    roles:response.data.roles,
                    accessToken:response.data.accessToken
                }
            });
            return response.data.accessToken;
        }catch(err){
            //console.log("rf error:", err)
            setAuth(null)
            return false;
        }
        
    }

    return  refresh;
}

export default useRefreshToken;