import React,{useState, useEffect, useRef} from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'
import Select, {components} from 'react-select'
import {country2emoji2} from '../utils/util'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormOption from './FormOption'
import UploadReduse from './UploadReduse'
//import {LettersLookUp} from '../utils/util'

function Document(props) {
    const DOC = "518121050865dd84a15d1694f7a5ac18"; // DOCument
    const UPDDOC = "a37f1772da42f6923c44211aa96b9316"; // UPDate DOCument
    const ADDOP = "f111f9fd037ad5b891e4a1ab297c61c3" // ADD OPtion
    const defaultInfo ={msg:'', cl:''}
    const noOptionsText= "Δεν υπάρχουν επιλογές"
     const NoOptionsMessage = props => {
        const name = props?.selectProps?.name || false
        return (
           <components.NoOptionsMessage {...props}>
              {noOptionsText}
              <button className='add-option' onClick={()=>setShowOptionForm(name)}>Νέο</button> 
           </components.NoOptionsMessage>
        );
      };
    const timerID = useRef(null)
    const {docId}= useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const axiosPrivate = useAxiosPrivate()
    const {setAuth} = useAuth()

    const [document, setDocument] = useState(null)
    const [metadata, setMetadata] = useState(null)
    const [lang] = useState(location?.state?.lang||'gr')
    const [info,setInfo] = useState(defaultInfo)
    const [isLoad,setIsLoad] = useState(false)
    const [poetDetailsShow, setPoetDetailsShow] = useState(false)
    const [showOptionForm, setShowOptionForm]=useState(false)
    const [optFrmState, setOptFrmState] =useState(false)
    const [upFiles, setUpFiles] = useState([])
    const [progressUpload, setProgressUpload] = useState(false)
    const [inProgress, setInProgress] = useState(false)
    
    function multilineError(err){
        return err.split('\n').map((item, i )=>(<div key={`er_${i}`}>{item}</div>))
    }
    const workTitleOptions = metadata?.options.filter(o=>(o.field ==='work_title_id')).map(v=>({value:v.id, label:v.des_gr, label_gb:v.des_gb}))||[]
    const seriesTitleOptions = metadata?.options.filter(o=>(o.field ==='series_title_id')).map(v=>({value:v.id, label:v.des_gr, label_gb:v.des_gb}))||[]
    const workNumberOptions = metadata?.options.filter(o=>(o.field ==='work_number_id')).map(v=>({value:v.id, label:v.des_gr, label_gb:v.des_gb}))||[]
    const descriptionOptions = metadata?.options.filter(o=>(o.field ==='description_id')).map(v=>({value:v.id, label:v.des_gr, label_gb:v.des_gb}))||[]
    const subjectOptions = metadata?.options.filter(o=>(o.field ==='subject_id')).map(v=>({value:v.id, label:v.des_gr, label_gb:v.des_gb}))||[]
    const formatOptions = metadata?.options.filter(o=>(o.field ==='format_id')).map(v=>({value:v.id, label:v.des_gr, label_gb:v.des_gb}))||[]
    const publisherOptions = metadata?.options.filter(o=>(o.field ==='publisher_id')).map(v=>({value:v.id, label:v.des_gr, label_gb:v.des_gb}))||[]
    const instrumentOptions = metadata?.instrument.map(v=>({value:v.id, label:v.instrument_gr, label_gb:v.instrument_gb}))||[]
    const composerOptions = metadata?.composer.map(i=>{
        const des_gr = i.des_gr?`(${i.des_gr})`:''
        const des_gb = i.des_gb?`(${i.des_gb})`:''
        const lbl = `${i.surname_gr||''} ${i.first_name_gr||''} ${des_gr}`
        const lbl_gb = `${i.surname_gb||''} ${i.first_name_gb||''} ${des_gb}`
        return({
            value:i.id,
            label:lbl.trim(),
            label_gb:lbl_gb.trim()
            })
    })||[]
    const poetOptions = metadata?.poet.map(i=>{
        const des_gr = i.des_gr?`(${i.des_gr})`:''
        const des_gb = i.des_gb?`(${i.des_gb})`:''
        const lbl = `${i.surname_gr||''} ${i.first_name_gr||''} ${des_gr}`
        const lbl_gb = `${i.surname_gb||''} ${i.first_name_gb||''} ${des_gb}`
        return({
            value:i.id,
            label:lbl.trim(),
            label_gb:lbl_gb.trim()
            })
    })||[]
    const poetDetails = document ?document.poet.map((dr,i)=>{
        const  poet =metadata?.poet?.find(p=>p.id===dr.id)||''
    return(
    <div key={`dp${i}`}>
        <span className='poet-rem'>{poet? `${poet.surname_gr||''} ${poet.des_gr||''}`:''}</span>
        <span className='flag-lang'>{country2emoji2('GR')}</span>
        <input type='text' className='poet-rem' name='rem_gr' value={dr.rem_gr||''} onChange={(e)=>handleTextChangeArr(e,i)}/>
        <span className='flag-lang'>{country2emoji2('GB')}</span>
        <input type='text' className='poet-rem' name='rem_gb' value={dr.rem_gb||''} onChange={(e)=>handleTextChangeArr(e,i)}/>
    </div>)}):''

    const filePath = upFiles? upFiles.map((f,i)=>
        <li key={`fp_${i}`}>
            <label htmlFor={`org_path_${i}`}>{f.org_file_name}</label>
            <input type="text" name="org_path" id={`org_path_${i}`} value={f.org_path ||''} onChange={(e)=>handleTextChangePath(e,i)}/>
        </li>
    ):''

    function  filterOptionFull (option, inputValue){
        //console.log('opt',option)
        const translate={ά:'α', έ:'ε',ή:'η',ί:'ι',ϊ:'ι',ΐ:'ι',ό:'ο',ύ:'υ',ϋ:'υ',ΰ:'υ', ώ:'ω',ς:'σ', ü :'u', é:'e', ä:'a'}
        const pattern = /[άέήίϊΐόύϋΰώςüéä]/g
        const allOption = option.data.label.trim().toLowerCase() + ' ' +option.data.label_gb.trim().toLowerCase()
        const optionStr = allOption.trim().toLowerCase().replace(pattern, match=>translate[match])
        const inputStr = inputValue ? inputValue.trim().toLowerCase().replace(pattern, match=>translate[match]) :''
        //console.log(option)
        return inputValue ? optionStr.includes(inputStr) : true
    }

    function formatOptionLabel({ label, label_gb}){
        return <>
            <div><span className='flag-lang'>{country2emoji2('GR')}</span><span>{label}</span></div>
            <div><span className='flag-lang'>{country2emoji2('GB')}</span><span className='sec-lang'>{label_gb}</span></div>
            </>
    }

    
    const customStyles = {
        control: (base, state) => ({
            ...base,
            width:'100%',
            border:'1px solid var(--sec-color)',
            boxShadow:0,
            '&:hover':{
                borderColor:'var(--sec-color)',
              }
            
        }),
        menu: base =>({
            ...base,
            width:'100%', 
            border:'1px solid var(--sec-color)',
            //border:'1px solid #555555',
            outline:'none',
            boxShadow:'none',
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isDisabled
                ? undefined
                : isSelected
                ? 'var(--pr-color)'
                : isFocused
                ? 'rgb(179, 126, 172,0.25)'
                : undefined,
                color: isDisabled
                ? undefined
                : isSelected
                ? '#fff':undefined,
                
              cursor: isDisabled ? 'not-allowed' : 'default',
            }
          }
      }

      function handleSelectChange(v, fld ){
        if(v && document[fld]===v.value)
          return
        setDocument(prev=>({...prev, [fld]:v ? parseInt(v.value):null}))
      }

      function handleMultiSelectChange(e, field){
        if(field==='poet'){
            setDocument(prev=>({...prev,[field]:Array.isArray(e)?
                e.map(x=>{
                    const poet = prev[field].find(p=>p.id===x.value) || {id:x.value,rem_gr:null, rem_gb:null}
                    return poet
                })
                :[],   
            }))
        }else{
            setDocument(prev=>({...prev,[field]:Array.isArray(e)?e.map(x=>x.value):[]}))
        }
    }

   
      function handleTextChange(ev,){
        const patternYear = /^-?\d{0,4}$/
        const patternPage = /^\d{0,12}$/
        const {name, value}= ev.target
       // const str = changeLang ? value.replace(/[\u0370-\u03ff\u1f00-\u1fff]/g, m=>LettersLookUp[m]):value
       if(ev.target.name==='size' && !patternPage.test(value) && value!==""){
        return
       }else if(ev.target.name==='document_year' && !patternYear.test(value) && value!==""){
        return
       }
        setDocument(prev=>({...prev, [name]:value||null}))
      }

      function handleTextChangeArr(ev,idx=null){
        const {name,value}=ev.target
        const newPoet = document.poet.map((p,i)=>{
            if(i===idx){
                return({...p, [name]:value||null})
            }else{
                return p
            }
        })
        setDocument(prev=>({...prev,poet:newPoet}))
      }

      function handleTextChangePath(ev,idx=null){
       
        const {name,value}=ev.target
        const newUpFiles = upFiles.map((f,i)=>{
            if(i===idx){
                const filterVal = value? value.replace("\\", "/").replace("//","/"):''
                return({...f, [name]:filterVal||null})
            }else{
                return f
            }
        })

        setDocument(prev=>({...prev,files:newUpFiles}))
      }
     
      function addOption(item, e){
        const {field} = item
        setOptFrmState(true)
        axiosPrivate.post("/documents.php",{item,act:ADDOP})
        .then(response=>{
            const {accessToken, obj, payload} = response.data
            if(obj==='options'){
                setMetadata(prev=>({...prev, options:[...prev.options, payload]}))
                setDocument(prev=>({...prev,[field]:payload.id}))
                setOptFrmState(false)
            }else if(obj==='instrument'){
                setMetadata(prev=>({...prev, instrument:[...prev.instrument, payload]}))
                setDocument(prev=>({...prev,[field]:[...prev[field],payload.id]}))
                setOptFrmState(false)
            }else if(obj==='poet'){
                const poet={id:payload.id,rem_gr:null,rem_gb:null}
                setMetadata(prev=>({...prev, poet:[...prev.poet, payload]}))
                setDocument(prev=>({...prev,[field]:[...prev[field],poet]}))
                setOptFrmState(false)
            }else if(obj==='composer'){
                setMetadata(prev=>({...prev, composer:[...prev.composer, payload]}))
                setDocument(prev=>({...prev,[field]:[...prev[field],payload.id]}))
                setOptFrmState(false)
            }
            setAuth(prev=>({...prev,accessToken }))
            setShowOptionForm(false)
        })

        .catch(err=>{
            setOptFrmState(false)
            const {message} = err?.response?.data
            setInfo({msg:message, cl:"err-msg"})
        })
      }

      function cancelOption(){
        setInfo(defaultInfo)
        setShowOptionForm(false)
      }
/* ---------------------------------------------------------------- */
      function appendFiles(obj){
        //setDocument(prev=>({...prev, files:arrFile}))
        if(Array.isArray(obj)){
            setUpFiles(obj)
        }else{
            setProgressUpload(obj)
        }
        //console.log(upFiles) 
      }
      function saveDocument(ev){
        setInProgress(true)
        const doc = Object.assign({}, document,{files:upFiles})
        
        axiosPrivate.post("/documents.php",{document:doc,act:UPDDOC})
        .then(response=>{
            const {accessToken, docId, files, message} = response.data
            setAuth(prev=>({...prev,accessToken }))
            setUpFiles([])
            setDocument(prev=>({...prev,id:docId, files:files}))
            setInfo({msg:message,cl:'suc-msg'})
            
        })
        .catch(err=>{
            console.log(err)
            const {message}=err?.response?.data
            setInfo({msg:message,cl:'err-msg'})
        })
        .finally(()=>{
            setInProgress(false)
            timerID.current = setTimeout(()=>{
                setInfo(defaultInfo)
            },3000)
        })
      }
    

    useEffect(() => {
        const pattern = /^[1-9][0-9]*$/g;
        window.scrollTo(0,0)
        if(pattern.test(docId) || docId ==='new' ){
            setIsLoad(true)
            axiosPrivate.post("/documents.php",{id:docId,act:DOC})
            .then(response=>{
                const {accessToken, document, metadata} = response.data
                setAuth(prev=>({...prev,accessToken }))
                setDocument(document)
                setMetadata(metadata)
            })
            .catch(err=>{
                console.log(err)
                setInfo({msg:"Bad URL", cl:"err-msg"})
            })
            .finally(()=>{
                setIsLoad(false)
            })
        }else{
            setInfo({msg:'BAD URL', cl:'err-msg'})
        }
    }, [docId,lang, axiosPrivate, setAuth])

    useEffect(() => {
        const timer = timerID.current
        return () => {
            clearTimeout(timer)
        }
    }, [])
   
    
    if(document)
    return (
        <main>
            <div className="doc-form-container">
                <div>
                   <label> {document?.id ==='new' ? `Νέα Εγγραφή`:`ID-${document.id}`}</label>
                </div>
                <div>
                    <label htmlFor='work_title_id'>Τίτλος Έργου</label>
                    {showOptionForm && showOptionForm==='work_title_id' ?
                    <FormOption addOption={addOption}  cancelOption={cancelOption} obj="options" field={showOptionForm} optFrmState={optFrmState}/>
                    :
                    <Select 
                        components={{NoOptionsMessage}}
                        name='work_title_id' 
                        id='work_title_id'
                        options={workTitleOptions} 
                        isClearable={true}
                        isMulti={false}
                        value={workTitleOptions.find(m=>m.value===document.work_title_id)||null}
                        onChange={e=>handleSelectChange(e, 'work_title_id')}
                        menuPlacement='auto'
                        filterOption = {filterOptionFull}    
                        styles={customStyles}
                        placeholder={''}
                        formatOptionLabel={formatOptionLabel}
                    />
                    }
                    {/*value={workTitleOptions.filter(d=>document.work_title_id.includes(d.value))}*/}
                </div> 
               
                
                <div>
                    <label htmlFor='series_title_id'>Τίτλος Σειράς</label>
                    {showOptionForm && showOptionForm==='series_title_id' ?
                    <FormOption addOption={addOption}  cancelOption={cancelOption} obj="options" field={showOptionForm} optFrmState={optFrmState}/>
                    :
                    <Select 
                        components={{NoOptionsMessage}}
                        name='series_title_id' 
                        id='series_title_id'
                        options={seriesTitleOptions} 
                        isClearable={true}
                        isMulti={false}
                        value={seriesTitleOptions.find(m=>m.value===document.series_title_id)||null}
                        onChange={e=>handleSelectChange(e, 'series_title_id')}
                        menuPlacement='auto'
                        filterOption = {filterOptionFull}    
                        styles={customStyles}
                        placeholder={''}
                        formatOptionLabel={formatOptionLabel}
                    />
                    }
                     {/*noOptionsMessage={()=>noOptionsText}*/}
                </div>
                <div>
                    <label htmlFor='subtitle_gr'>Υπότιτλος</label>
                    <div style={{whiteSpace: 'nowrap'}}>
                        <span className='flag-lang'>{country2emoji2('GR')}</span>
                        <input  type='text' className='doc-form' name='subtitle_gr' id='subtitle_gr' value={document.subtitle_gr||''} onChange={(e)=>handleTextChange(e)} />
                    </div>
                    <div style={{whiteSpace: 'nowrap'}}>
                        <span className='flag-lang'>{country2emoji2('GB')}</span>
                        <input type='text' className='doc-form' name='subtitle_gb' id='subtitle_gb' value={document.subtitle_gb||''} onChange={(e)=>handleTextChange(e)}/>
                    </div>
                </div>
                <div>
                    <label htmlFor='work_number_id'>Αριθμός Έργου</label>
                    {showOptionForm && showOptionForm==='work_number_id' ?
                    <FormOption addOption={addOption}  cancelOption={cancelOption} obj="options" field={showOptionForm} optFrmState={optFrmState}/>
                    :
                    <Select
                        components={{NoOptionsMessage}} 
                        name='work_number_id' 
                        id='work_number_id'
                        options={workNumberOptions} 
                        isClearable={true}
                        isMulti={false}
                        value={workNumberOptions.find(m=>m.value===document.work_number_id)||null}
                        onChange={e=>handleSelectChange(e, 'work_number_id')}
                        menuPlacement='auto'
                        filterOption = {filterOptionFull}    
                        styles={customStyles}
                        placeholder={''}
                    />
                    }
                </div>
               
                <div>
                    <label htmlFor='description_id'>Περιγραφή</label>
                    {showOptionForm && showOptionForm==='description_id' ?
                    <FormOption addOption={addOption}  cancelOption={cancelOption} obj="options" field={showOptionForm} optFrmState={optFrmState}/>
                    :
                    <Select 
                        components={{NoOptionsMessage}} 
                        name='description_id' 
                        id='description_id'
                        options={descriptionOptions} 
                        isClearable={true}
                        isMulti={false}
                        value={descriptionOptions.find(m=>m.value===document.description_id)||null}
                        onChange={e=>handleSelectChange(e, 'description_id')}
                        menuPlacement='auto'
                        filterOption = {filterOptionFull}    
                        styles={customStyles}
                        placeholder={''}
                        formatOptionLabel={formatOptionLabel}
                    />
                    }
                </div>
                <div>
                    <label htmlFor='subject_id'>Θέμα</label>
                    {showOptionForm && showOptionForm==='subject_id' ?
                    <FormOption addOption={addOption}  cancelOption={cancelOption} obj="options" field={showOptionForm} optFrmState={optFrmState}/>
                    :
                    <Select 
                        components={{NoOptionsMessage}} 
                        name='subject_id' 
                        id='subject_id'
                        options={subjectOptions} 
                        isClearable={true}
                        isMulti={false}
                        value={subjectOptions.find(m=>m.value===document.subject_id)||null}
                        onChange={e=>handleSelectChange(e, 'subject_id')}
                        menuPlacement='auto'
                        filterOption = {filterOptionFull}    
                        styles={customStyles}
                        placeholder={''}
                        formatOptionLabel={formatOptionLabel}
                    />
                    }
                </div>
                <div>
                    <label htmlFor='orchestration_id'>Ενορχήστρωση</label>
                    {showOptionForm && showOptionForm==='orchestration' ?
                    <FormOption addOption={addOption}  cancelOption={cancelOption} obj="instrument" field={showOptionForm} optFrmState={optFrmState}/>
                    :
                    <Select 
                        components={{NoOptionsMessage}} 
                        name='orchestration' 
                        id='orchestration_id'
                        options={instrumentOptions} 
                        isClearable={true}
                        isMulti={true}
                        value={instrumentOptions.filter(d=>document.orchestration.includes(d.value))}
                        onChange={e=>handleMultiSelectChange(e, 'orchestration')}
                        menuPlacement='auto'
                        filterOption = {filterOptionFull}    
                        styles={customStyles}
                        placeholder={''}
                        formatOptionLabel={formatOptionLabel}
                    />
                    }
                </div>
                <div>
                    <label htmlFor='format_id'>Μορφότυπο</label>
                    {showOptionForm && showOptionForm==='format_id' ?
                    <FormOption addOption={addOption}  cancelOption={cancelOption} obj="options" field={showOptionForm} optFrmState={optFrmState}/>
                    :
                    <Select 
                        components={{NoOptionsMessage}} 
                        name='format_id' 
                        id='format_id'
                        options={formatOptions} 
                        isClearable={true}
                        isMulti={false}
                        value={formatOptions.find(m=>m.value===document.format_id)||null}
                        onChange={e=>handleSelectChange(e, 'format_id')}
                        menuPlacement='auto'
                        filterOption = {filterOptionFull}    
                        styles={customStyles}
                        placeholder={''}
                        formatOptionLabel={formatOptionLabel}
                    />
                }
                </div>
                <div>
                    <label htmlFor='composer_id'>Συνθέτης</label>
                    {showOptionForm && showOptionForm==='composer' ?
                    <FormOption addOption={addOption}  cancelOption={cancelOption} obj="composer" field={showOptionForm} optFrmState={optFrmState}/>
                    :
                    <Select 
                        components={{NoOptionsMessage}} 
                        name='composer' 
                        id='composer_id'
                        options={composerOptions} 
                        isClearable={true}
                        isMulti={true}
                        value={composerOptions.filter(d=>document.composer.includes(d.value))}
                        onChange={e=>handleMultiSelectChange(e, 'composer')}
                        menuPlacement='auto'
                        filterOption = {filterOptionFull}    
                        styles={customStyles}
                        placeholder={''}
                        formatOptionLabel={formatOptionLabel}
                    />
                    }
                </div>
                <div>
                    <label htmlFor='poet_id'>Ποιητής - Στιχουργός</label>
                    {showOptionForm && showOptionForm==='poet' ?
                    <FormOption addOption={addOption}  cancelOption={cancelOption} obj="poet" field={showOptionForm} optFrmState={optFrmState}/>
                    :
                    <Select 
                        components={{NoOptionsMessage}} 
                        name='poet' 
                        id='poet_id'
                        options={poetOptions} 
                        isClearable={true}
                        isMulti={true}
                        value={poetOptions.filter(d=>document.poet.map(p => p.id).includes(d.value))}
                        onChange={e=>handleMultiSelectChange(e, 'poet')}
                        menuPlacement='auto'
                        filterOption = {filterOptionFull}    
                        styles={customStyles}
                        placeholder={''}
                        formatOptionLabel={formatOptionLabel}
                    />
                    }
                    <div style={{color:'var(--sec-color)',cursor:'pointer',fontSize:'0.8rem',marginTop:'0.5rem'}} onClick={()=>setPoetDetailsShow(prev=>!prev)}>
                        Λεπτομέριες...
                        <FontAwesomeIcon icon={`fa-solid ${poetDetailsShow?'fa-chevron-up':'fa-chevron-down'}`} />
                    </div>
                    <div className='rem-poet-details' style={{display:poetDetailsShow ? 'block':'none'}}>
                        {poetDetails}
                    </div>
                </div>

                <div>
                    <label htmlFor='publisher_id'>Εκδότης</label>
                    {showOptionForm && showOptionForm==='publisher_id' ?
                    <FormOption addOption={addOption}  cancelOption={cancelOption} obj="options" field={showOptionForm} optFrmState={optFrmState}/>
                    :
                    <Select 
                        components={{NoOptionsMessage}} 
                        name='publisher_id' 
                        id='publisher_id'
                        options={publisherOptions} 
                        isClearable={true}
                        isMulti={false}
                        value={publisherOptions.find(m=>m.value===document.publisher_id)||null}
                        onChange={e=>handleSelectChange(e, 'publisher_id')}
                        menuPlacement='auto'
                        filterOption = {filterOptionFull}    
                        styles={customStyles}
                        placeholder={''}
                        formatOptionLabel={formatOptionLabel}
                    />
                    }
                </div>
                <div>
                    <label htmlFor='location_gr'>Τόπος</label>
                    <div style={{whiteSpace: 'nowrap'}}>
                        <span className='flag-lang'>{country2emoji2('GR')}</span>
                        <input  type='text' className='doc-form' name='location_gr' id='location_gr' value={document.location_gr||''} onChange={(e)=>handleTextChange(e)} />
                    </div>
                    <div style={{whiteSpace: 'nowrap'}}>
                        <span className='flag-lang'>{country2emoji2('GB')}</span>
                        <input type='text' className='doc-form' name='location_gb' id='location_gb' value={document.location_gb||''} onChange={(e)=>handleTextChange(e)} />
                    </div>
                </div>

                <div>
                    <label htmlFor='publish_number'>Αριθμός Έκδοσης</label>
                    <input  type='text'  name='publish_number' id='publish_number' value={document.publish_number||''} onChange={(e)=>handleTextChange(e)} />
                </div>
                <div>
                    <label htmlFor='inscription_gr'>Αφιέρωση</label>
                    <div style={{whiteSpace: 'nowrap'}}>
                        <span className='flag-lang'>{country2emoji2('GR')}</span>
                        <input  type='text' className='doc-form' name='inscription_gr' id='inscription_gr' value={document.inscription_gr||''} onChange={(e)=>handleTextChange(e)} />
                    </div>
                    <div style={{whiteSpace: 'nowrap'}}>
                        <span className='flag-lang'>{country2emoji2('GB')}</span>
                        <input type='text' className='doc-form' name='inscription_gb' id='inscription_gb' value={document.inscription_gb||''} onChange={(e)=>handleTextChange(e)} />
                    </div>
                </div>
                <div>
                    <label htmlFor='revision_gr'>Διασκευή</label>
                    <div style={{whiteSpace: 'nowrap'}}>
                        <span className='flag-lang'>{country2emoji2('GR')}</span>
                        <input  type='text' className='doc-form' name='revision_gr' id='revision_gr' value={document.revision_gr||''} onChange={(e)=>handleTextChange(e)} />
                    </div>
                    <div style={{whiteSpace: 'nowrap'}}>
                        <span className='flag-lang'>{country2emoji2('GB')}</span>
                        <input type='text' className='doc-form' name='revision_gb' id='revision_gb' value={document.revision_gb||''} onChange={(e)=>handleTextChange(e)} />
                    </div>
                </div>
                <div>
                    <label htmlFor='size'>Έκταση τεκμηρίου (σελίδες)</label>
                    <input  type='text'  name='size' id='size' value={document.size||''} onChange={(e)=>handleTextChange(e)} />
                    {!parseInt(document.size)?'':parseInt(document.size)===1?'σελίδα':'σελίδες'}
                </div>
                <div>
                    <label htmlFor='document_year'>Χρονολογία (έτος)</label>
                    <input  type='text'  name='document_year' id='document_year' value={document.document_year||''} onChange={(e)=>handleTextChange(e)} />
                    {parseInt(document.document_year) <0 ?`${Math.abs(document.document_year)} π.Χ.`:''}
                </div>
                <UploadReduse 
                 title="Μεταφόρωση Αρχείων" 
                 droptext="Αφήστε εδώ τα αρχεία σας..."
                 uploadFileList={document.files}
                 appendFiles={appendFiles}
                 updateButton={false}
                />
                {!progressUpload?
                <div>
                    {upFiles.length > 0 ?
                    <span style={{color:'var(--sec-color)'}}>Θέση αρχείων κατά την εξαγωγή</span>
                    :''
                    }
                    <ul className="file-path">
                    {filePath}
                    </ul>
                </div>:''
                }   
                {/*<button onClick={(e)=>{console.log('files:', document?.files)}}>ok</button>*/}
                {!progressUpload?
                    <div className='update-part'>
                        <button className='update-btn' onClick={(e)=>saveDocument(e)}>
                            {inProgress ?<FontAwesomeIcon icon="fa-solid fa-spinner" spin />:''}
                            Ενημέρωση
                        </button>
                        <button className='return-btn' onClick={()=>{navigate(-1, { replace: true })}}>Επιστροφή</button>
                    </div>
                :''}
            </div>
           
            {info.msg ?
                <div className={`info-box ${info.cl}`}>
                    <FontAwesomeIcon icon="fa-solid fa-circle-xmark" className='close-info' onClick={()=>setInfo(defaultInfo)}/>
                    {multilineError(info.msg)}
                </div>:''
            }           
        </main>
    )
    if(isLoad)
        return(
            <div>Load...</div>
        )
    if(info.msg)
        return (
            <div className={info.cl}>{info.msg}</div>
        )
    
}
export default Document