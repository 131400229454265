import React, {useState} from 'react'
import {Link, Outlet} from 'react-router-dom'
import logo1 from './media/img/logo-71.png'
import './utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAuth from './hooks/useAuth'
import {getPageText} from './utils/util'
//import axios from './api/axiosapi'
//import Logout from './components/Logout'
//import Archive from './components/Archive'

function Layout(props) {
    const {auth, lang, setLang} = useAuth()
    const [menuIsOpen, setMenuIsOpen]=useState(false)
    const isConnected  =auth?.user ? true:false

    //const {auth}=useAuth()
    //console.log("Layout:",auth)
    const icon = auth?.accessToken ? 
      auth?.roles ?['fas', 'user-check']:['fas','user-lock']
      :
      ['fas', 'user']

    const content =  getPageText('mainMenu') 

    function handleMenu(){
      setMenuIsOpen(prev=>!prev)
      
    }

     function  closeMenu(e){
      setMenuIsOpen(false)
      sessionStorage.clear()
    }

    function changeLang(language,e){
      localStorage.setItem("lang",language)
      setLang(language)
      closeMenu(e)
    }
    /*
    async function logout(){
      
      setMenuIsOpen(false)
      try{
        const response = await axios.get("logout.php", {
            withCredentials:true
        });
        console.log(response)
        setAuth(null);
          
      }catch(err){
        console.log("logout error:", err)
      }

    }
   */
    //console.log(lang)
    return (
      <div className={`container ${menuIsOpen?`over-fl`:''}`}>
        <header>
          <Link to="/" style={{textDecoration:'none'}}>
            <div className='logo'>
              <img src={logo1} alt="logo"/>
              <div className='logo-des'>
                <h4>{content[lang].headerLine1}</h4>
                <h3>{content[lang].headerLine2}</h3>
                <h3>{content[lang].headerLine3}</h3>
              </div> 
            </div>
          </Link>
          {
            <nav className={menuIsOpen?'open':''} >
              <div className='list-container'>
                <ul className='lang'>
                  <li onClick={(e)=>{changeLang('gr',e)}} className={lang==='gr'?'active':''}>EL</li>
                  <li onClick={(e)=>{changeLang('gb',e)}} className={lang==='gb'?'active':''}>EN</li>
                </ul> 
                <ul className='opt'>
                  <li><Link to="/" className='lnk' onClick={(e)=>closeMenu(e)}>{content[lang].archive}</Link></li>
                  {/*<li><Link to="/guide" className='lnk' onClick={(e)=>closeMenu(e)}>Οδηγίες</Link></li>*/}
                  <li><Link to="/search" className='lnk' onClick={(e)=>closeMenu(e)}>{content[lang].search}</Link></li>
                  <li><Link to="/catalog" className='lnk' onClick={(e)=>closeMenu(e)}>{content[lang].catalog}</Link></li>
                  <li>{
                    !isConnected ?
                    <Link to="/login" className='lnk' onClick={(e)=>closeMenu(e)}>{content[lang].login}</Link>
                    :
                    (
                      /*<label  className='lnk'  onClick={(e)=>logout(e)}>Αποσύνδεση</label>*/
                      ''
                    )
                  }</li>
                  <li><Link to="/contact" className='lnk' onClick={(e)=>closeMenu(e)}>{content[lang].contact}</Link></li>
                </ul> 
              </div>
            </nav>
          
          }
          {/* <div className='rest'> */}
            {
              auth?.user ?
             
              <Link to="/profile" className='menu-image profile-image' onClick={(e)=>closeMenu(e)}>
                <FontAwesomeIcon icon={icon} className="menu-image user"/>
              </Link>
              
              :
              <FontAwesomeIcon icon={icon} className="menu-image user"/>
            }
            {!menuIsOpen?
            <FontAwesomeIcon icon={['fas', 'bars']} className={`menu-image bars`} onClick={()=>handleMenu()}/>
            :
            <FontAwesomeIcon icon={['fas', 'xmark']} className={`menu-image exit`} onClick={()=>handleMenu()}/>
            }
           
          {/* </div> */}
          
          
        </header>
          
        <Outlet />
        <footer>
          <div className='social'>
            <a className='ext-link' href='https://www.instagram.com/athenian_mandolinata_n_lavdas/?hl=el' target='_blank' rel='noreferrer'>
              <FontAwesomeIcon icon="fa-brands fa-instagram" />
            </a>
            <a className='ext-link' href='https://www.facebook.com/profile.php?id=100064189442389' target='_blank' rel='noreferrer'>
              <FontAwesomeIcon icon="fa-brands fa-facebook" />
            </a>
            <a className='ext-link' href='mailto:athenianmandolinata@yahoo.com' target='_blank' rel='noreferrer'>
              <FontAwesomeIcon icon="fa-solid fa-envelope" />
            </a>
          </div>
          <div className="footer-logos">
            <div className="group-left">
              <div className='copyright'>
                Copyright &copy;2023 {content[lang].copyrightText}
              </div>
              <div className='design'>
                <a className="ext-link" href={lang==="gb" ? 'https://www.idea-no.com':'https://www.idea-no.com/el/graphic-design-athens-greece.php'} target='_blank' rel='noreferrer'>
                  NO IDEA graphic design
                </a>
              </div>
            </div>
            <div className="group-right">
              <div className="sponsors">
                <img className='yppo-logo' src={process.env.PUBLIC_URL + `/icons/yppo_logo_${lang}.png`} alt="yppo logo" />
                <img src={process.env.PUBLIC_URL + `/icons/HMC_Logo.png`} alt="HMC logo" />
              </div>
            </div>
          </div>
          
          
        </footer>
        
      </div>
    );
}

export default Layout