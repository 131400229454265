import React from 'react'
import useAuth from '../hooks/useAuth'
import '../style/contact.css'
//import '../utils/fontawesome'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import axios from '../api/axiosapi'


function Contact(props) {
  const {lang} = useAuth()
  return (
    <main>
      <h1>{lang==="gb" ? 'Contact us':'Επικοινωνία'}</h1>
      <h2>{lang==="gb" ? 'Contact phone number':'Τηλέφωνο Επικοινωνίας'}: <span className='phone'>6938 133364</span></h2>
      <h2>Website : <a className='site-link' href='https://athenianmandolinata.com/' target='_blank' rel="noreferrer">https://athenianmandolinata.com/</a></h2>
      {/*<p><input type="text" name="userMail" value={userMail} onChange={(e)=>setUserMail(e.target.value)} /></p>*/}
      {/*<button onClick={(e)=>{sendTestEmali()}}>e-mail Test</button>*/}
      {/*<p>{result}</p>*/}
     
    </main>
  )
}
export default Contact