import React, {useState, useEffect, useRef} from 'react'
import {Link, useLocation } from 'react-router-dom'
import { Confirm } from 'react-st-modal'
import '../utils/fontawesome'
import { checkUserStatus } from '../utils/util'
import Pagination from './Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import useAuth from '../hooks/useAuth'


function SearchUser(props) {
    
    const axiosPrivate = useAxiosPrivate()
    const {setAuth} = useAuth()

    const columns =["ID","Όνμα χρήστη", "Επώνυμο  Όνομα / Τηλέφωνο", "Λήξη Συνδρομής",  "Κατάσταση", "Διαγραφή"]
    const headerCol= columns.map((c, i)=>
            <th key={i} className='tbl-head'>
                {c}
            </th>
        )

    const NoData = <tr><td colSpan={columns.length}>No Data</td></tr>
    const loadData = <tr><td colSpan={columns.length}>Loading...</td></tr>
    const DLTUSR = "2a00f7edd7d4b6b16c97d1920cf2cdfc" // DeLeTe USeR
    
    const searchUser = {
        id:null,
        username:null,
        surname:null,
        firstName:null,
        tel:null,
        userStatus:200,
        expireDate:null,
        rpp:50,
        page:1
    }
    const location = useLocation()
    const searchKey = `${location.pathname}-search`
    const [search, setSearch]=useState(sessionStorage.getItem(searchKey)?JSON.parse(sessionStorage[searchKey]):searchUser)
    const [users, setUsers] = useState([])
    const [totalRows, setTotalRows] = useState(0)
    const [go, setGo] = useState(0)
    const [loading, setLoading] = useState(false)
    const [inProgress, setInProgress] = useState(0)
    const timer = useRef(null)
   

    function handleInputChange(ev){
        const {name, value} = ev.target
        setSearch(prev=>({...prev, [name]:value}))
    }

    
    function handleSearch(ev){
        //setGo(1);
        setSearch(prev=>({...prev, page:1}))
        setGo(2)
    }
/*
    function checkUserStatus(exp, con, adm){
        if(parseInt(con)===0){
            return {cl:'inactive-user',des:'Ανενεργός'}
        }else if(parseInt(adm)===1){
            return {cl:'active-user',des:'Ενεργός'}
        }else if(exp === null){
            return {cl:'pending-user',des:'Εκκρεμεί'}
        }else if(parseInt(exp) >= 0 ){
            return {cl:'active-user',des:'Ενεργός'}
        }else{
            return {cl:'inactive-user',des:'Λήξη Συνδρ.'}
        }   
    }
*/
    function clearSearch(ev){
        //setSearch(initSearch())
        setSearch(searchUser)
        sessionStorage.removeItem(searchKey)
    }

    function storeLocal(){
        //console.log()
        sessionStorage.setItem(location.pathname, window.scrollY)
        sessionStorage.setItem(searchKey, JSON.stringify(search))
    }

    function deleteContact(id){
        setInProgress(id)
        axiosPrivate.post("/users.php",{id,act:DLTUSR})
        .then(response=>{
            const {accessToken} = response.data
            setAuth(prev=>({...prev,accessToken }))
            setGo(1)
            //setInfo({msg:message,cl:'suc-msg'})
        })
        .catch(err=>{
            
            const {message}=err?.response?.data
            console.log(message)
            //setInfo({msg:message,cl:'err-msg'})
        })
        .finally(()=>{
            setInProgress(0)
            timer.current = setTimeout(()=>{
                //setInfo(defaultInfo)
            },3000)
        })
    }

    

    function changePage(ev){
        const {value}=ev.target
        setSearch(prev=>({...prev,page:value}))
        setGo(2)
    }
    
    const usersContent = users.map((u,i)=>{
        const ust = checkUserStatus(u.expire, u.connection_status, u.is_admin)
        return (
                <tr key={i}>
                    <td data-label='ID'> {u.id}</td>
                    <td data-label='Όνμα χρήστη'><Link className='usr-lnk' to={u.id.toString()} onClick={()=>storeLocal()}> {u.user_name}</Link></td>
                    <td data-label='Επώνυμο Όνομα / Τηλέφωνο'>{u.is_admin ?  <FontAwesomeIcon className='user-symb' icon='fa-solid fa-gear'/>:''} {u.surname} {u.first_name}<div style={{marginTop:'0.5rem' ,fontSize:'0.8rem',color:'var(--txt-55-color)' }}>{u.tel}</div></td>
                    {/*<td>{u.tel}</td>*/}
                    <td data-label='Λήξη Συνδρομής'>{u.expire_date ? u.expire_date.split('-').reverse().join('/'): '-'}</td>
                    <td data-label='Κατάσταση'><span className={ust.cl}>{ust.des}</span></td>
                    <td data-label='Ενέργειες'>
                    {inProgress===u.id ? <FontAwesomeIcon icon="fa-solid fa-spinner" spin />:
                    <FontAwesomeIcon icon={['fas', 'trash-alt']} className='del-icon'   onClick={async () => {
                            const result = await Confirm("Να διαγραφεί η εγγραφή του χρήστη ?", 
                            'Διαγραφή Χρήστη', 'Ναι', 'Όχι' )
                            if (result) {
                                deleteContact(u.id)
                            } 
                      }}/>
                    }
                    </td>
                </tr>
        )
    })

    useEffect(() => {
        
        function handleScroll(){
            const position = sessionStorage.getItem(location.pathname)
            if(position){
                timer.current = setTimeout(()=>{
                window.scrollTo(0,parseInt(position))
                sessionStorage.removeItem(location.pathname)
                },0)
                //console.log('pos:' , parseInt(position))
            }
        }
        if(go){
            setLoading(true)
            if(go === 2){
               window.scrollTo(0,0)
               //console.log('zero')
            }
            
            sessionStorage.setItem(searchKey, JSON.stringify(search))
            axiosPrivate.post("/users.php",{search})
            .then(response=>    {
                const {accessToken, users, totalRows} = response.data
                setUsers(users)
                setAuth(prev=>({...prev,accessToken }))
                setTotalRows(totalRows)
                if(go === 1){
                    handleScroll()
                }
                setGo(0) 
            })
            .catch(err=>console.log(err)
            )
            .finally(()=>setLoading(false)) 
        }
    },[go, search, setAuth, axiosPrivate, searchKey, location.pathname])

    
    
    useEffect(() => {
      setGo(1)
      return () => {
        if(timer.current)
            clearTimeout(timer.current)
        }
    }, [])

   

  return (
    <div>
        <table className='user-table'>
            <thead>
                <tr>
                    <th colSpan={columns.length} style={{backgroundColor:"#fff"}}>
                        <ul className='users-bar'>
                        
                            <li><Link to=".." className='usr-lnk'><FontAwesomeIcon icon="fa-solid fa-turn-up" flip="horizontal" /> Διαχείριση</Link></li>
                            <li>Χρήστες</li>
                            <li><FontAwesomeIcon icon="fa-solid fa-eraser" className='eraser' onClick={()=>{clearSearch()}}/></li>
                            <li className="r-pos">
                                <Link className='new-lnk' to="new" onClick={()=>storeLocal()}>
                                    <FontAwesomeIcon  icon='fa-solid fa-user-plus'/>
                                </Link>
                            </li>
                        </ul>
                    </th>
                </tr>
                <tr>
                    <th colSpan={columns.length} style={{padding:0}}>
                    <form className='search-bar'>
                        
                        <div className='field-content'>
                            <label>user name</label>
                            <input type="text"  name="username" value={search.username || ''} onChange={(e)=>handleInputChange(e)}/>
                        </div>
                    
                    
                        <div className='field-content'>
                            <label>Επώνυμο</label>
                            <input type="text" name="surname" value={search.surname || ''} onChange={(e)=>handleInputChange(e)}/>
                        </div>
                        <div className='field-content'>
                            <label>Όνομα</label>
                            <input type="text" name="firstName" value={search.firstName || ''} onChange={(e)=>handleInputChange(e)}/>
                        </div>
                    
                        <div className='field-content tel'>
                            <label>Τηλέφωνο:</label>
                            <input type="text"  name="tel" value={search.tel || ''} onChange={(e)=>handleInputChange(e)}/>
                        </div>
                        
                        <div className='field-content'>
                            <label>ID</label>
                            <input type="text" className='sm-width'  name='id' value={search.id || ''} onChange={(e)=>handleInputChange(e)}/>
                        </div>

                        <div className='search-status'>
                            <label>Χρήστες</label>
                            <select name="userStatus" value={search.userStatus} onChange={(e)=>handleInputChange(e)}>
                                <option value="200">Όλοι</option>
                                <option value="0">Σε Εκκρεμότητα</option>
                                <option value="50">Με Ενεργή Συνδρομή</option>
                                <option value="-1">Απενεργοποιημένοι</option>
                                <option value="100">Διαχειριστές</option>
                            </select>
                        </div>


                        <div className='field-expire'>
                            <label>η συνδρομή λήγει μέχρι:</label>
                            <input type="date"  name="expireDate" value={search.expireDate || ''} onChange={(e)=>handleInputChange(e)}/>
                        </div>

                    

                        <div className='search-button'>
                            <button type='button' onClick={(e)=>handleSearch(e)}>
                            <FontAwesomeIcon icon='fa-solid fa-magnifying-glass'/>
                            </button>
                        </div>

                       
                        
                    </form>
                    </th>
                </tr>
                <tr>
                    {headerCol}
                </tr>
            </thead>
            <tbody>
            {loading 
                ? loadData 
                :
                usersContent.length > 0 
                ?  usersContent
                : NoData
            }
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={columns.length}>
                    ({parseInt(search.page -1)*search.rpp  + 1 } έως {parseInt(search.page)*search.rpp <= totalRows?parseInt(search.page)*search.rpp:totalRows}) από {totalRows}  
                    </td>
                </tr>
                <tr>
                    <td colSpan={columns.length}>
                    <Pagination totalRows={totalRows} currentPage={parseInt(search.page)} rpp={parseInt(search.rpp)} changePage={changePage}/>
                    </td>
                </tr>
            </tfoot>
        </table> 
    </div>

  )
}

export default SearchUser