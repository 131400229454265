import React, {useEffect, useState} from 'react'
import {Confirm} from 'react-st-modal'
import {country2emoji2} from '../utils/util'
import '../utils/fontawesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function OptionItem(props) {
    const {item, action}= props
    const {edit,progress} = props.edit
    const objArray = ['composer', 'poet']
    const [option, setOption] = useState(null)

    useEffect(() => {
      setOption(item)
    }, [item])
    

    function handleChange(ev, field=null){
        const {name, value} = ev.target
        if(field && field === "work_number_id"){
            setOption(prev=>({...prev, des_gr:value||null, des_gb:value||null}))
            return
        }
        setOption(prev=>({...prev, [name]:value||null}))
    }

    function cancel(){
        setOption(item)
        action('cancel', option)
    }
    function update(){
        action('update', option)
    }
    



    if(!option){
        return ''
    }
    if( objArray.includes(option?.obj)){
        return(
            <div className='opt-container'>
                <button className='opt-action' disabled={edit===false?false:true}  onClick={()=>action('edit', option)}>
                    <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                    <div className='opt-id'>{`ID-${option.id}`}</div>
                </button>
                <div className='opt-content-place'>
                    <div className='opt-content'>
                        <div className="opt-flag">
                            {country2emoji2('GR')}
                        </div>
                        <div className="opt-data">
                            <div className='opt-fieldset'>
                                <h3>Επώνυμο</h3>
                                { edit===parseInt(option.id)? 
                                <input type='text' name='surname_gr' value={option.surname_gr||''} onChange={(e)=>handleChange(e)} />
                                :<div className='opt-value'>{option.surname_gr}</div>
                                }
                            </div>
                            <div className='opt-fieldset'>
                                <h3>Όνομα</h3>
                                { edit===parseInt(option.id)? 
                                <input type='text' name='first_name_gr' value={option.first_name_gr||''} onChange={(e)=>handleChange(e)} />
                                :<div className='opt-value'>{option.first_name_gr}</div>
                                }
                            </div>
                            <div className='opt-fieldset'>
                                <h3>Παρατήρηση</h3>
                                { edit===parseInt(option.id)? 
                                <input type='text' name='des_gr' value={option.des_gr||''} onChange={(e)=>handleChange(e)} />
                                :<div className='opt-value'>{option.des_gr}</div>
                                }
                            </div>
                        </div>
                    </div>
                    <div  className='opt-content'>
                        <div className="opt-flag">
                            {country2emoji2('GB')}
                        </div>
                        <div className="opt-data">
                            <div className='opt-fieldset'>
                                <h3>Επώνυμο</h3>
                                { edit===parseInt(option.id)? 
                                <input type='text' name='surname_gb' value={option.surname_gb||''} onChange={(e)=>handleChange(e)} />
                                :<div className='opt-value'>{option.surname_gb}</div>
                                }
                            </div>
                            <div className='opt-fieldset'>
                                <h3>Όνομα</h3>
                                { edit===parseInt(option.id)? 
                                <input type='text' name='first_name_gb' value={option.first_name_gb||''} onChange={(e)=>handleChange(e)} />
                                :<div className='opt-value'>{option.first_name_gb}</div>
                                }
                            </div>
                            <div className='opt-fieldset'>
                                <h3>Παρατήρηση</h3>
                                { edit===parseInt(option.id)? 
                                <input type='text' name='des_gb' value={option.des_gb||''} onChange={(e)=>handleChange(e)} />
                                :<div className='opt-value'>{option.des_gb}</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {edit===parseInt(option.id)? 
                    !progress ?
                    <>
                        <button className="opt-action"   onClick={(e)=>update()}>
                            <FontAwesomeIcon icon="fa-solid fa-check" />
                        </button>
                        <button className="opt-action"  onClick={(e)=>cancel()}>
                            <FontAwesomeIcon icon="fa-solid fa-xmark" />
                        </button>
                    </>
                    :
                    <FontAwesomeIcon icon="fa-solid fa-spinner" spin  className='spinner'/>
                :   option.counter === 0 ?
                    <button className="opt-action opt-del" disabled={!edit===false} onClick={(e)=>action('delete', option)} >
                        <FontAwesomeIcon icon="fa-solid fa-trash-alt" />
                    </button>
                    :<div className='opt-counter'>{option.counter}</div>
                }
            </div>    
        )
    }else {
        return (
            <div className='opt-container'>   
                <button className='opt-action' disabled={edit===false?false:true}  onClick={()=>action('edit', option)}>
                    <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                    <div className='opt-id'>{`ID-${option.id}`}</div>
                    
                </button>
                <div className='opt-content-place'>
                    <div className='opt-content'>
                        <div className="opt-flag">
                            {country2emoji2('GR')}
                        </div>
                        <div className="opt-data">
                            <div className='opt-fieldset'>
                            {edit===parseInt(option.id)? 
                                <input type='text' name='des_gr' value={option.des_gr||''} onChange={(e)=>handleChange(e, option.field)} />
                                :<div className='opt-value'>{option.des_gr}</div>
                            }
                            </div>
                        </div>
                    </div>
                    <div  className='opt-content'>
                        <div className="opt-flag">
                            {country2emoji2('GB')}
                        </div>
                        <div className="opt-data">
                            <div className='opt-fieldset'>
                            {edit===parseInt(option.id)? 
                                <input type='text' name='des_gb' value={option.des_gb||''} onChange={(e)=>handleChange(e, option.field)} />
                                :<div className='opt-value'>{option.des_gb}</div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
                {edit===parseInt(option.id)? 
                    !progress ?
                    <>
                        <button className="opt-action"   onClick={(e)=>update()}>
                            <FontAwesomeIcon icon="fa-solid fa-check" />
                        </button>
                        <button className="opt-action" onClick={(e)=>cancel()}>
                            <FontAwesomeIcon icon="fa-solid fa-xmark" />
                        </button>
                    </>
                    :
                    <FontAwesomeIcon icon="fa-solid fa-spinner" spin className='spinner'/>
                :   option.counter === 0 ? 
                    <button className="opt-action opt-del" disabled={!edit===false} onClick={
                        async () => {
                            const result = await Confirm("Να διαγραφεί η εγγραφή ?", 
                            'Διαγραφή Επιλογής', 'Ναι', 'Όχι')
                            if (result) {
                                action('delete', option)
                            } 
                      }
                    }
                    
                    
                    >
                        <FontAwesomeIcon icon="fa-solid fa-trash-alt" />
                    </button>
                    :<div className='opt-counter'>{option.counter}</div>
                }
            </div>
        )
    }
}

export default OptionItem