import React from 'react'
//import useAxiosPrivate from '../hooks/useAxiosPrivate'
//import useAuth from '../hooks/useAuth'

function Guide() {
//  const axiosPrivate = useAxiosPrivate()
  //const {setAuth} = useAuth()

 /* const test = ()=>{
    
        axiosPrivate.post("/authenticate.php",{mode:'AUTH'})
        .then(resp=>{
        console.log('guide:',resp)
        setAuth(prev=>({...prev,accessToken:resp.data.accessToken }))
        })
        .catch(err=>console.log(err))
      
    
  }*/

  return (
    <>
    <h1>Οδηγίες</h1>
    {/*<button onClick={()=>test()}>test refresh</button>*/}
    </>
  )
}

export default Guide