import contentArr from './content.json'
export function checkUserStatus(exp, con, adm ){
    if( con === null && adm === null){
        return ''
    }

    if(parseInt(con)===0){
        return {cl:'inactive-user',des:'Ανενεργός', clf:'inact-usr-frm',desf:'Ανενεργός',sd:false}
    }else if(parseInt(adm)===1){
        return {cl:'active-user',des:'Ενεργός',clf:'act-usr-frm',desf:'Ενεργός',sd:true}
    }else if(parseInt(exp) >= 0 ){
        return {cl:'active-user',des:'Ενεργός',clf:'act-usr-frm',desf:'Ενεργός',sd:true }
    }else if(exp === null){
        //console.log("++",exp)
        return {cl:'pending-user',des:'Εκκρεμεί',clf:'pending-usr-frm',desf:'Εκκρεμεί',sd:false}
    }else{
        return {cl:'inactive-user',des:'Λήξη Συνδρ.',clf:'inact-usr-frm',desf:'Λήξη Συνδρομής',sd:true}
    }   
}

export function daysDiff(expireDateStr){
    if(expireDateStr){
        const expArray = expireDateStr.split('-')
        if(expArray.length === 3 && Array.isArray(expArray)){
            const eY = parseInt(expArray[0])
            const eM = parseInt(expArray[1]) - 1
            const eD = parseInt(expArray[2])
            const curTempDate= new Date()
            const cY = curTempDate.getFullYear()
            const cM = curTempDate.getMonth()
            const cD = curTempDate.getDate()
            const currentDate = new Date(cY, cM, cD)
            const expireDate = new Date(eY, eM, eD)
            const diff = (expireDate - currentDate) / 86400000  // 1 day
            //console.log('diff', diff)
            return diff >= 0 ? Math.ceil(diff) : Math.floor(diff)
        }
    }
    return null
}

export function randomPass(max){
    const randomCounter =  maxVal =>{
        const digitCount=[0,0,0];
        const min = 1 
        let limit =0
        limit= maxVal - min 
        digitCount[0] = Math.floor(min + Math.random() * (limit - min))
        limit = maxVal - digitCount[0] - 1
        digitCount[1] = Math.floor(min + Math.random() * (limit - min))
        digitCount[2] = maxVal - digitCount[0] - digitCount[1]
        digitCount.sort((a,b)=>b>a)
        return digitCount
           
    }
    
    const shuffleArray = array => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array
    }
    const count = randomCounter(max)
    const letterStr = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz'
    const symbolStr = "!@#$%^&*-+=/<>~?_"
    const num = [1,2,3,4,5,6,7,8,9]
    const lns=[];
    lns.push(shuffleArray(letterStr.split('')));//.slice(0, count[0]);
    lns.push(shuffleArray(num));//.slice(0, count[1]);
    lns.push(shuffleArray(symbolStr.split('')));//.slice(0, count[2]);   
    const passArr=[]
    for(let j=0;j<3;j++){
        for(let i=0;i<count[j];i++){
            //const idx = Math.floor(Math.random()*lns[j].length)
            //const digit = lns[j][idx]
            passArr.push(lns[j][Math.floor(Math.random()*lns[j].length)])
        }   
    }
    const pass =  shuffleArray(passArr).join('')
    //const pass =  shuffleArray(letters.concat(numbers, symbols)).join('')
    return pass
}

export  function country2emoji2(country_code) {
    const OFFSET = 127397;
    const codeArray = Array.from(country_code.toUpperCase());
    return String.fromCodePoint(...codeArray.map((c) => c.charCodeAt() + OFFSET));
}

 export  function getPageText(pg){
    const page = contentArr.find(p=>p.page===pg)
    const {content}=page
    return  content
    
}

/* export const  LettersLookUp ={
    'ς':'w', 'ε':'e', 'ρ':'r', 'τ':'t', 'υ':'y', 'θ':'u', 'ι':'i', 'ο':'o', 'π':'p', 
    'α':'a', 'σ':'s', 'δ':'d', 'φ':'f', 'γ':'g', 'η':'h', 'ξ':'j', 'κ':'k', 'λ':'l',
    'ζ':'z', 'χ':'x', 'ψ':'c', 'ω':'v', 'β':'b', 'ν':'n','μ':'m',
     ';':'q', ':':'Q', 'Ε':'E', 'Ρ':'R', 'Τ':'T', 'Υ':'Y', 'Θ':'U', 'Ι':'I', 'Ο':'O', 'Π':'P', 
    'Α':'A', 'Σ':'S', 'Δ':'D', 'Φ':'F', 'Γ':'G', 'Η':'H', 'Ξ':'J', 'Κ':'K', 'Λ':'L',
    'Ζ':'Z', 'Χ':'X', 'Ψ':'C', 'Ω':'V', 'Β':'B', 'Ν':'N','Μ':'M'
} */

/* myObject = {
    "a": "α",
    "b": "β",
    "c": "ψ",
    "d": "δ",
    "e": "ε",
    "f": "φ",
    "g": "γ",
    "h": "η",
    "i": "ι",
    "j": "ξ",
    "k": "κ",
    "l": "λ",
    "m": "μ",
    "n": "ν",
    "o": "ο",
    "p": "π",
    "q": ";",
    "r": "ρ",
    "s": "σ",
    "t": "τ",
    "u": "θ",
    "v": "ω",
    "w": "ς",
    "x": "χ",
    "y": "υ",
    "z": "ζ",
    ";": ""
};
 */

/** /^([Α-ωA-z0-9-_+\.]+\/)+$/gm */